import { NgModule, Optional, SkipSelf } from '@angular/core';
import { LacertaMenuApiService } from './menu-api.service';
import { preventReimport } from '@lacerta/util';

@NgModule({
  providers: [LacertaMenuApiService],
})
export class LacertaMenuModule {
  constructor(@Optional() @SkipSelf() parentModule: LacertaMenuModule) {
    preventReimport(parentModule, 'LacertaMenuModule');
  }
}
