import { ModuleWithProviders, NgModule, Optional, Provider, SkipSelf } from '@angular/core';
import { LacertaCoreConfig } from './core.config';
import { NxModule } from '@nrwl/angular';
import { preventReimport } from '@lacerta/util';
import { LacertaCmsModule, LacertaWagtailPage } from '@lacerta/cms';
import { LacertaNgrxModule } from '@lacerta/ngrx';
import { LacertaTranslationModule } from '@lacerta/i18n';
import { LacertaMetaModule } from '@lacerta/meta';
import { LacertaFormControlComponent } from '@lacerta/form';

@NgModule({
  imports: [LacertaNgrxModule, LacertaCmsModule, LacertaMetaModule],
})
export class LacertaCoreModule {
  constructor(@Optional() @SkipSelf() parentModule: LacertaCoreModule) {
    preventReimport(parentModule, 'LacertaCoreModule');
  }

  static forRoot<TPage extends LacertaWagtailPage>(config: LacertaCoreConfig<TPage>): ModuleWithProviders<LacertaCoreModule> {
    return {
      ngModule: LacertaCoreModule,
      providers: [
        ...(NxModule.forRoot().providers as Provider[]),
        ...(LacertaNgrxModule.forRoot({ production: config.environment?.production || false }).providers as Provider[]),
        ...(LacertaCmsModule.forRoot({
          menuParentPageType: config.menuParentPageType,
          rootPageType: config.rootPageType,
          wagtailComponentTypeMapping: config.wagtailComponentTypeMapping,
          customRoutes: config.customRoutes,
        }).providers as Provider[]),
        ...(LacertaTranslationModule.forRoot({
          prodMode: config.environment?.production || false,
          defaultLang: config.defaultLang,
          importTranslationFile: config.importTranslationFile,
        }).providers as Provider[]),
        ...(config.metaConfig ? [LacertaMetaModule.forRoot(config.metaConfig).providers as Provider[]] : []),
        ...(config.formControlComponent ? [{ provide: LacertaFormControlComponent, useValue: config.formControlComponent }] : []),
      ],
    };
  }
}
