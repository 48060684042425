import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CMS_FEATURE_KEY, pageAdapter, State } from './cms.reducer';
import { currentSlugByUrl, elementNotNullOrUndefined } from '@lacerta/util';
import { LacertaWagtailPage } from '../page.model';
import { Dictionary } from '@ngrx/entity';
import { routerSelectors } from '@lacerta/ngrx';

interface GetByTypeProps {
  type: string;
}
interface GetByLanguageProps {
  language: string;
}
type GetByTypeAndLanguageProps = GetByTypeProps & GetByLanguageProps;

export const getCmsState = createFeatureSelector<State>(CMS_FEATURE_KEY);

const wagtailPageSelectors = pageAdapter.getSelectors();

const getRootPageId = createSelector(getCmsState, (state: State) => state.rootPage);

const getSummaryPagesState = createSelector(getCmsState, (state) => state.summaryPages);
const getDetailPagesState = createSelector(getCmsState, (state) => state.detailPages);
const getPagesCountByTypeState = createSelector(getCmsState, (state) => state.pagesCountByType);

export const getCurrentSlug = createSelector(routerSelectors.selectUrl, (url) => currentSlugByUrl(decodeURIComponent(url)));

const getSummaryPageEntities = createSelector(getSummaryPagesState, wagtailPageSelectors.selectEntities);
const getSummaryPageArray = createSelector(getSummaryPagesState, wagtailPageSelectors.selectAll);

const getMenuIds = createSelector(getCmsState, (state: State) => state.menuPageIds);
export const getMenuSummaryPages = createSelector(getSummaryPageEntities, getMenuIds, (pages, ids) =>
  ids ? ids.map((id) => pages[id]).filter(elementNotNullOrUndefined) : []
);

const getDetailPageArray = createSelector(getDetailPagesState, wagtailPageSelectors.selectAll);

export const getCurrentPageId = createSelector(getSummaryPageArray, getCurrentSlug, (entities, slug) => {
  const summaryPages = entities.filter((entity) => entity.meta.slug === slug);
  return summaryPages.length ? summaryPages[0].id : undefined;
});

const getDetailPageEntities = createSelector(getDetailPagesState, wagtailPageSelectors.selectEntities);

export const getCurrentSummaryPage = createSelector(getSummaryPageEntities, getCurrentPageId, (entities, id) =>
  id ? entities[id] : undefined
);

export const getCurrentDetailPage = createSelector(getDetailPageEntities, getCurrentPageId, (entities, id) =>
  id ? entities[id] : undefined
);

export const getRootPage = createSelector(getSummaryPageEntities, getRootPageId, (entities, pageId) =>
  pageId ? entities[pageId] : undefined
);

const pageByTypeProjector = (entities: LacertaWagtailPage[], props: { type: string | undefined }) =>
  entities.filter((entity) => entity.meta.type === props.type);

export const getDetailPagesByType = () => createSelector(getDetailPageArray, pageByTypeProjector);
export const getSummaryPagesByType = () => createSelector(getSummaryPageArray, pageByTypeProjector);

export const getDetailPagesTotalCountByType = () =>
  createSelector(getPagesCountByTypeState, (counts: Record<string, number>, props: { type: string }) => counts[props.type]);

export const getDetailPageById = () =>
  createSelector(getDetailPageEntities, (entities: Dictionary<LacertaWagtailPage>, props: { id: number }) => entities[props.id]);

export const getAllLanguages = () => createSelector(getDetailPagesByType(), (pages) => pages.map((page) => page.language?.code));

export const getAvailableLanguages = () =>
  createSelector(getAllLanguages(), getLanguage, (languages, currentLang) =>
    languages.filter((lang) => currentLang && lang !== currentLang)
  );

export const getTranslatedPageSlug = () =>
  createSelector(
    getCurrentDetailPage,
    (page: LacertaWagtailPage | undefined, props: { language: string }) =>
      page?.translations?.filter((translation) => translation.language.code === props.language)[0]?.meta?.slug
  );

export const getDetailPageByTypeAndLanguage = () =>
  createSelector(
    getDetailPagesByType(),
    (pages, props) => pages.filter((page) => page.language?.code === (props as GetByTypeAndLanguageProps).language)[0]
  );
const pageByLanguageProjector = (pages: LacertaWagtailPage[], language?: string) =>
  pages.filter((page) => page.language?.code === language)[0];

export const getDetailPageByTypeInCurrentLanguage = () => createSelector(getDetailPagesByType(), getLanguage, pageByLanguageProjector);
export const getMenuPagesByType = () => createSelector(getMenuSummaryPages, pageByTypeProjector);

export const getCurrentRoutedComponent = createSelector(routerSelectors.selectCurrentRoute, (data) => data?.component);

export const getLanguage = createSelector(getCmsState, (state) => state.language);
