import { HttpParams } from '@angular/common/http';
import { PagesApiParams } from '../page';
import { objectKeys } from '@lacerta/util';

export const buildHttpParams = (paramObject?: Partial<PagesApiParams>): HttpParams => {
  let httpParams = new HttpParams();
  if (paramObject) {
    objectKeys(paramObject).forEach((key) => {
      const params = paramObject[key];
      const value = params instanceof Array ? params.join() : params;
      if (value !== undefined) {
        httpParams = httpParams.append(key, value.toString());
      }
    });
  }
  return httpParams;
};
