import { NgModule } from '@angular/core';
import { ProjectTypeComponent } from './project-type/project-type.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { SharedModule } from '../../shared/shared.module';
import { SnakeModule } from '../snake/snake.module';
import { ProjectGalleryComponent } from './project-gallery/project-gallery.component';
import { LacertaMapperModule } from '@lacerta/util';
import { LacertaImageModule } from '@lacerta/ui';

@NgModule({
  imports: [SharedModule, SnakeModule, SharedModule, LacertaMapperModule, LacertaImageModule],
  declarations: [ProjectTypeComponent, ProjectDetailComponent, ProjectGalleryComponent],
  exports: [ProjectTypeComponent, ProjectDetailComponent, ProjectGalleryComponent],
})
export class ProjectModule {}
