/* eslint-disable @typescript-eslint/naming-convention */
import { WagtailMetaModel, WagtailModel } from '../wagtail.model';

export enum WagtailPagesField {
  all = '*',
  title = 'title',
  body = 'body',
  seo_title = 'seo_title',
  search_description = 'search_description',
  search_image = 'search_image',
}

export interface WagtailPageMeta extends WagtailMetaModel {
  html_url: string;
  slug: string;
  show_in_menus: boolean;
  seo_title: string;
  search_description: string;
  first_published_at: Date;
  parent?: {
    id: number;
    meta: {
      type: string;
      detail_url: string;
      html_url: string;
    };
    title: string;
  };
}

export interface WagtailPage extends WagtailModel {
  meta: WagtailPageMeta;
}
