<a routerLink="/home"><div class="logo nosnake"></div></a>

<nav>
  <div class="hamburger nosnake" [ngClass]="{ 'is-active': menuActive }">
    <a (click)="menuActive = !menuActive"> <span class="bar"></span> <span class="bar"></span> <span class="exclamation">!</span> </a>
  </div>
  <div class="menu" [ngClass]="{ 'is-active': menuActive }">
    <div class="overlay" (click)="menuActive = false"></div>

    <ul>
      <li *ngFor="let item of items">
        <a
          [routerLink]="routerLink(item)"
          routerLinkActive="is-active"
          [routerLinkActiveOptions]="{ exact: !!item.exact }"
          (click)="menuActive = !menuActive"
        >
          <div class="mark"></div>
          {{ item.page.title }}
        </a>
      </li>
    </ul>
    <ul class="buttons">
      <li>
        <a href="https://www.instagram.com/riffagency/" target="_blank"><fa-icon [icon]="faInstagram"></fa-icon></a>
      </li>
      <li>
        <a href="https://open.spotify.com/playlist/1Mgf5IFGqbCgR7iw1XZtPp?si=a9DLAz5IR4KyVToXMT9_HQ" target="_blank"
          ><fa-icon [icon]="faSpotify"></fa-icon
        ></a>
      </li>
      <li><button class="play" (click)="play()">play SNAKE</button></li>
    </ul>
  </div>
</nav>
