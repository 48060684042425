import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { LacertaMetaService } from './meta.service';
import { LacertaMetaConfig } from './meta.config';
import { LacertaMetaNgrxModule } from './+state/meta-ngrx.module';
import { Title } from '@angular/platform-browser';
import { preventReimport } from '@lacerta/util';
import { LacertaWagtailPage } from '@lacerta/cms';

@NgModule({
  imports: [LacertaMetaNgrxModule],
})
export class LacertaMetaModule {
  public static forRoot<TPage extends LacertaWagtailPage>(metaConfig: LacertaMetaConfig<TPage>): ModuleWithProviders<LacertaMetaModule> {
    return {
      ngModule: LacertaMetaModule,
      providers: [{ provide: LacertaMetaConfig, useValue: metaConfig }, Title, LacertaMetaService],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: LacertaMetaModule) {
    preventReimport(parentModule, 'LacertaMetaModule');
  }
}
