import { LacertaImageChooserBlock, LacertaImageChooserBlockImage, LacertaImageRenditionField } from '@lacerta/cms';
import { LacertaUiImage } from '@lacerta/ui';

export const mapImageChooserBlockToRenditionImage = (
  imageBlock: LacertaImageChooserBlock,
  rendition?: string
): LacertaImageChooserBlockImage => {
  const imageRendition = rendition && imageBlock?.renditions?.[rendition];
  return imageRendition ? imageRendition : imageBlock?.original_image;
};

export const mapImageChooserBlockToUiImage = (imageBlock: LacertaImageChooserBlock, rendition?: string): LacertaUiImage => ({
  focalPoint: imageBlock?.focal_point,
  src: mapImageChooserBlockToRenditionImage(imageBlock, rendition)?.src,
});

export const mapImageRenditionFieldToUiImage = (image: LacertaImageRenditionField): LacertaUiImage => ({
  focalPoint: image?.focal_point,
  src: image.url,
});
