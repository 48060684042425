<div
  [class.playing]="play && !auto && !gameover"
  #playground
  class="playground"
  (panleft)="move('left')"
  (panright)="move('right')"
  (pandown)="move('down')"
  (panup)="move('up')"
>
  <canvas #canvas [width]="width" [height]="height"></canvas>

  <div *ngIf="gameover" (click)="close()" class="overlay">
    <div (click)="nothing($event)" class="gameover">
      <h1>Game Over</h1>
      <p>Score: {{ score }}</p>
      <button (click)="restart()">restart</button>
    </div>
  </div>

  <div class="images">
    <span #red_arc_svg [inlineSVG]="'../../../assets/images/svg/rode_bocht.svg'"></span>

    <img #black_arc src="../../../assets/images/svg/zwarte_bocht.svg" alt="zwarte bocht" />
    <img #red_arc src="../../../assets/images/svg/rode_bocht.svg" alt="rode bocht" />
    <img #white_arc src="../../../assets/images/svg/witte_bocht.svg" alt="witte bocht" />
  </div>
</div>

<div [class.playing]="play && !auto && !gameover" class="controls">
  <div class="left" (click)="move('left')"></div>
  <div class="right" (click)="move('right')"></div>
  <div class="up" (click)="move('up')"></div>
  <div class="down" (click)="move('down')"></div>
</div>
