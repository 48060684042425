import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LacertaCmsModule } from '@lacerta/cms';
import { LacertaEvenOddModule } from '@lacerta/util';

@NgModule({
  exports: [CommonModule, RouterModule, LacertaCmsModule, LacertaEvenOddModule],
})
export class SharedModule {}
