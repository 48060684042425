<section [class.playing]="playing">
  <div class="snake"><riff-snake theme="red"></riff-snake></div>
  <div class="container">
    <div class="intro nosnake">
      <div class="left">
        <div class="tagline">
          <strong>a fan-based agency</strong><br />
          we run on music
        </div>
        <div class="wij-zijn">
          <a routerLink="/wij-zijn-riff">
            wij zijn <span class="arrow" aria-label="My icon" [inlineSVG]="'../../../assets/images/svg/right-arrow-forward.svg'"></span>
          </a>
        </div>
      </div>
      <div class="right"><img class="logo" src="../../../assets/images/svg/Logo_zwart.svg" alt="Riff" /></div>
    </div>
  </div>
  <div class="project">
    <div class="image nosnake">
      <a *ngIf="project" routerLink="/{{ project.meta.slug }}">
        <svg
          *ngIf="project.thumbnail"
          xmlns="http://www.w3.org/2000/svg"
          version="1.0"
          width="640"
          height="640"
          viewBox="0 0 640 640"
          preserveAspectRatio="xMidYMid meet"
          id="svg5"
        >
          <defs xmlns="http://www.w3.org/2000/svg">
            <pattern id="img1" patternUnits="userSpaceOnUse" width="640" height="640">
              <image [attr.xlink:href]="baseUrl + project.thumbnail.url" x="0" y="0" width="640" height="640"></image>
            </pattern>
          </defs>
          <path
            d="M 349.31361,4.2112142 C 221.41237,20.984117 140.83044,76.817393 70.517003,146.80585 c -11.137058,13.40463 -21.962514,28.45088 -30.440224,42.54697 -1.07436,1.95823 -5.013682,9.07906 -8.594884,16.02188 -3.581203,6.76477 -7.162406,13.70757 -8.057708,15.13176 -10.418164,21.19097 -15.3624083,41.78472 -19.6966125,62.30726 -4.65556323,22.0746 -5.0136835,67.11386 -0.7162405,88.1203 9.570137,55.25076 39.604592,108.00329 73.056533,147.75727 15.041052,17.62407 28.112443,30.44155 46.197513,45.57331 33.24592,26.23706 40.13423,30.27481 77.89115,49.84582 48.29413,23.91127 109.79301,31.38655 158.46823,21.89655 42.4155,-10.99272 68.25957,-20.45227 101.88519,-41.12278 46.42318,-29.93235 84.86543,-70.10751 108.15232,-104.67622 14.91749,-26.18526 32.29,-55.07629 41.18381,-81.35552 3.2231,-9.07905 6.26716,-17.80208 6.80433,-19.22625 13.80123,-47.48674 12.81,-46.57607 17.54785,-69.96216 1.07437,-8.01093 3.04405,-22.2526 4.47649,-31.50967 C 643.46318,203.76239 636.39242,102.74174 598.92346,49.428501 573.35549,23.236333 526.49047,10.420781 494.35234,5.4573679 464.8074,0.82881699 412.61288,-3.4663003 349.31361,4.2112142 Z"
            id="path2"
            fill="url(#img1)"
            [attr.fill]="'url(' + url + '#img1)'"
          ></path>
        </svg>

        <!-- <div class="thumbnail" *ngIf="project.thumbnail" [ngStyle]="{ 'background-image': 'url(' + project.thumbnail.url + ')' }"></div> -->
        <!-- <div class="cover"></div> -->
        <div class="counter" *ngIf="projectIndex >= 0">{{ projectIndex + 1 }} / {{ projects.length }}</div>
      </a>
      <!-- <img [src]="selected.thumbnail.url" [alt]="selected.title"/> -->
    </div>
    <div class="text nosnake">
      <a *ngIf="projectIndex >= 0" [routerLink]="'/' + project.meta.slug">
        <h2>{{ project.title }}</h2>
        <span class="tag_line" *ngIf="project.tag_line">{{ project.tag_line }}</span>
        <div class="arrow">
          <div class="svg" aria-label="My icon" [inlineSVG]="'../../../assets/images/svg/right-arrow-forward.svg'"></div>
        </div>
      </a>
    </div>
  </div>
  <div class="preload">
    <ng-container *ngIf="projectIndex + 1 < projects.length">
      <img *ngIf="projects[projectIndex + 1].thumbnail" [src]="projects[projectIndex + 1].thumbnail.url" class="preload" alt="preload" />
    </ng-container>
  </div>
</section>

<div class="mobile projects">
  <div *ngFor="let project of projects; index as i" class="project">
    <div class="image nosnake">
      <a routerLink="/{{ project.meta.slug }}">
        <svg
          *ngIf="project.thumbnail"
          xmlns="http://www.w3.org/2000/svg"
          version="1.0"
          width="640"
          height="640"
          viewBox="0 0 640 640"
          preserveAspectRatio="xMidYMid meet"
          id="svg5"
        >
          <defs xmlns="http://www.w3.org/2000/svg">
            <pattern [attr.id]="'mobileimg' + i" patternUnits="userSpaceOnUse" width="640" height="640">
              <image [attr.xlink:href]="baseUrl + project.thumbnail.url" x="0" y="0" width="640" height="640"></image>
            </pattern>
          </defs>
          <path
            d="M 349.31361,4.2112142 C 221.41237,20.984117 140.83044,76.817393 70.517003,146.80585 c -11.137058,13.40463 -21.962514,28.45088 -30.440224,42.54697 -1.07436,1.95823 -5.013682,9.07906 -8.594884,16.02188 -3.581203,6.76477 -7.162406,13.70757 -8.057708,15.13176 -10.418164,21.19097 -15.3624083,41.78472 -19.6966125,62.30726 -4.65556323,22.0746 -5.0136835,67.11386 -0.7162405,88.1203 9.570137,55.25076 39.604592,108.00329 73.056533,147.75727 15.041052,17.62407 28.112443,30.44155 46.197513,45.57331 33.24592,26.23706 40.13423,30.27481 77.89115,49.84582 48.29413,23.91127 109.79301,31.38655 158.46823,21.89655 42.4155,-10.99272 68.25957,-20.45227 101.88519,-41.12278 46.42318,-29.93235 84.86543,-70.10751 108.15232,-104.67622 14.91749,-26.18526 32.29,-55.07629 41.18381,-81.35552 3.2231,-9.07905 6.26716,-17.80208 6.80433,-19.22625 13.80123,-47.48674 12.81,-46.57607 17.54785,-69.96216 1.07437,-8.01093 3.04405,-22.2526 4.47649,-31.50967 C 643.46318,203.76239 636.39242,102.74174 598.92346,49.428501 573.35549,23.236333 526.49047,10.420781 494.35234,5.4573679 464.8074,0.82881699 412.61288,-3.4663003 349.31361,4.2112142 Z"
            id="path2"
            fill="url(#img1)"
            [attr.fill]="'url(' + url + '#mobileimg' + i + ')'"
          ></path>
        </svg>
      </a>
    </div>
    <div class="text nosnake">
      <a [routerLink]="'/' + project.meta.slug">
        <h2>{{ project.title }}</h2>
        <span class="tag_line" *ngIf="project.tag_line">{{ project.tag_line }}</span>
        <div class="arrow">
          <div class="svg" aria-label="My icon" [inlineSVG]="'../../../assets/images/svg/right-arrow-forward.svg'"></div>
        </div>
      </a>
    </div>
  </div>
</div>

<footer><a class="contact nosnake" routerLink="/contact">contact</a></footer>
