import { NgModule } from '@angular/core';

import { InlineSVGModule } from 'ng-inline-svg';

import { RiffAppComponent } from './app.component';
import { RiffCoreModule } from './core/core.module';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [BrowserModule, RiffCoreModule, InlineSVGModule.forRoot(), FontAwesomeModule, HammerModule],
  declarations: [RiffAppComponent],
  bootstrap: [RiffAppComponent],
})
export class AppModule {}
