import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { SnakeService } from '../snake/snake.service';
import { faInstagram, faSpotify } from '@fortawesome/free-brands-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'riff-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit, OnDestroy {
  showPopup = false;
  faInstagram = faInstagram;
  faSpotify = faSpotify;
  faTimes = faTimes;

  constructor(private renderer: Renderer2, private snakeService: SnakeService) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'contact');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'contact');
  }

  play() {
    this.showPopup = false;
    this.snakeService.play();
  }
}
