<div class="background"></div>
<div class="snake white-theme">
  <ng-container *ngIf="fullPage$ | async as wijZijn">
    <riff-snake [amount]="10"></riff-snake>
    <section class="intro">
      <div class="nosnake container">
        <div class="jump-3 content" [innerHtml]="wijZijn.intro"></div>
        <div class="arrow" (click)="scroll(koppen)"></div>
      </div>
    </section>
    <section #koppen class="koppen">
      <div class="nosnake container">
        <h1>Koppen</h1>
        <div class="kop femke">
          <img src="../../../assets/images/femke_web.jpg" alt="Femke" />
          <div class="text">
            <h5><b>Femke Coopmans</b>, director of everything else</h5>
            resting heart rate: 60 bpm<br />
            <a href="tel:+32477933425">+32(0)477 93 34 25</a><br />
            <a href="mailto:femke@riff-agency.be">femke@riff-agency.be</a>
            <span class="links">
              <a target="_blank" href="https://www.instagram.com/femkecoopmans/"><fa-icon [icon]="faInstagram"></fa-icon></a>
              <a target="_blank" href="https://www.linkedin.com/in/femke-coopmans-9676926/"><fa-icon [icon]="faLinkedinIn"></fa-icon></a>
            </span>
          </div>
        </div>
        <div class="kop johan">
          <img src="../../../assets/images/johan_web.jpg" alt="Johan" />
          <div class="text">
            <h5><b>Johan Martens</b>, director of photography</h5>
            resting heart rate: 58 bpm<br />
            <a href="tel:+32475864976">+32(0)475 86 49 76</a><br />
            <a href="mailto:johan@riff-agency.be">johan@riff-agency.be</a>
          </div>
        </div>
        <div class="kop lennart">
          <img src="../../../assets/images/lennart_web.jpg" alt="Lennart" />
          <div class="text">
            <h5><b>Lennart Coopmans</b>, technology director</h5>
            resting heart rate: 56 bpm<br />
            <a href="tel:+32486453031">+32(0)486 45 30 31</a><br />
            <a href="mailto:lennart@riff-agency.be">lennart@riff-agency.be</a>
            <span class="links">
              <a target="_blank" href="https://www.linkedin.com/in/lennart-coopmans-119412a/"><fa-icon [icon]="faLinkedinIn"></fa-icon></a>
            </span>
          </div>
        </div>
      </div>
    </section>
    <section class="team">
      <div class="nosnake container">
        <h1>Team</h1>
        <div class="columns">
          <div class="intro width_16" [innerHtml]="wijZijn.team_intro"></div>
          <div class="columns teams">
            <div class="column">
              <div class="widthfix">
                <div class="team" *ngFor="let team of wijZijn.team | odd">
                  <!-- TODO figure out how ng-templates work... -->
                  <h4>{{ team.value.name }}</h4>
                  <ul class="members">
                    <li *ngFor="let member of team.value.members" class="member">
                      <a *ngIf="member.link" [href]="member.link" target="_blank">{{ member.name }}</a>
                      <span *ngIf="!member.link">{{ member.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="widthfix">
                <div class="team" *ngFor="let team of wijZijn.team | even">
                  <h4>{{ team.value.name }}</h4>
                  <ul class="members">
                    <li *ngFor="let member of team.value.members" class="member">
                      <a *ngIf="member.link" [href]="member.link" target="_blank">{{ member.name }}</a>
                      <span *ngIf="!member.link">{{ member.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="nosnake container"><div class="jump-3 headers-jump-back content" [innerHtml]="wijZijn.manifesto"></div></div>
    </section>
  </ng-container>
</div>
<footer><a class="contact nosnake" routerLink="/contact">contact</a></footer>
