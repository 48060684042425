export enum RiffWagtailPageType {
  home = 'riff.RiffHomePage',
  root = 'riff.RiffRootPage',
  project = 'riff.RiffProject',
  projectDetail = 'riff.ProjectDetailPage',
  projectIndex = 'riff.ProjectIndexPage',
  projectType = 'riff.RiffProjectTypePage',
  wijZijn = 'riff.WijZijnPage',
  contact = 'riff.ContactPage',
}
