import { Component } from '@angular/core';
import { LacertaCmsNgrxFacade } from '../+state/cms.facade';
import { map } from 'rxjs/operators';
import { filterNullOrUndefined } from '@lacerta/util';

@Component({
  template: `
    <ng-container *ngIf="inputs$ | async as inputs">
      <ng-container *ngIf="inputs.type | mapToComponent | async as component">
        <ndc-dynamic [ndcDynamicComponent]="component" [ndcDynamicInputs]="inputs"></ndc-dynamic>
      </ng-container>
    </ng-container>
  `,
})
export class LacertaDynamicRoutePageComponent {
  inputs$ = this.wagtailNgrxFacade.currentSummaryPage$.pipe(
    filterNullOrUndefined(),
    map((page) => ({ type: page.meta.type, context: page }))
  );

  constructor(private wagtailNgrxFacade: LacertaCmsNgrxFacade) {}
}
