import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AttributesMap } from 'ng-dynamic-component';

@Component({
  selector: 'lacerta-dynamic-block',
  template: `
    <ng-container *ngIf="blockType">
      <ndc-dynamic
        *ngIf="blockType | mapToComponent | async as component"
        [ndcDynamicComponent]="component"
        [ndcDynamicInputs]="{ type: blockType, context: blockValue }"
        [ndcDynamicAttributes]="attributes"
      ></ndc-dynamic>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LacertaDynamicBlockComponent {
  @Input()
  blockType?: string;

  @Input()
  blockValue?: unknown;

  @Input()
  attributes: AttributesMap = {};
}
