import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { createEffect } from '@ngrx/effects';
import { filterEmptyValues, mapContextToMetaValues, mapRootPageToMetaValues } from '../meta.util';
import { LacertaMetaField, LacertaMetaFields } from '../meta.model';
import { LacertaMetaService } from '../meta.service';
import { LacertaMetaConfig } from '../meta.config';
import { Title } from '@angular/platform-browser';
import { LacertaCmsNgrxFacade, LacertaWagtailPage } from '@lacerta/cms';

const mergeMetaValuesWithPrevious = map(([currentMeta, metaValuesToAdd]: LacertaMetaFields[]) => ({ ...currentMeta, ...metaValuesToAdd }));
const mapPageToTitle = (page?: LacertaWagtailPage) => page?.meta?.seo_title || page?.title;

@Injectable()
export class LacertaMetaEffects {
  private rootPageMetaValues$ = this.lacertaCmsNgrxFacade.rootPage$.pipe(map(mapRootPageToMetaValues), map(filterEmptyValues));
  private pageBySlugMetaValues$ = this.lacertaCmsNgrxFacade.currentSummaryPage$.pipe(map(mapContextToMetaValues), map(filterEmptyValues));
  private pageByIdMetaValues$ = this.lacertaCmsNgrxFacade.currentDetailPage$.pipe(map(mapContextToMetaValues), map(filterEmptyValues));
  private dynamicPageMetaValues$ = this.lacertaCmsNgrxFacade.currentDetailPage$.pipe(
    map((page) => page && this.componentMetaMapping.get(page.meta.type) && this.componentMetaMapping.get(page.meta.type)?.(page))
  );

  private rootPageTitle$ = this.lacertaCmsNgrxFacade.rootPage$.pipe(map(mapPageToTitle));
  private pageBySlugTitle$ = this.lacertaCmsNgrxFacade.currentSummaryPage$.pipe(map(mapPageToTitle));

  setMeta$ = createEffect(
    () =>
      this.rootPageMetaValues$.pipe(
        tap((defaultMeta) => this.lacertaMetaService.setMeta(defaultMeta)),
        switchMap((currentMeta) => this.pageBySlugMetaValues$.pipe(map((newMetaValues) => [currentMeta, newMetaValues]))),
        mergeMetaValuesWithPrevious,
        tap((metaValues: LacertaMetaFields) => this.lacertaMetaService.setMeta(metaValues)),
        switchMap((currentMeta) => this.pageByIdMetaValues$.pipe(map((newMetaValues) => [currentMeta, newMetaValues]))),
        mergeMetaValuesWithPrevious,
        tap((metaValues: LacertaMetaFields) => this.lacertaMetaService.setMeta(metaValues)),
        switchMap((currentMeta) => this.dynamicPageMetaValues$.pipe(map((newMetaValues) => [currentMeta, newMetaValues ?? {}]))),
        mergeMetaValuesWithPrevious,
        tap((metaValues: LacertaMetaFields) => this.lacertaMetaService.setMeta(metaValues))
      ),
    { dispatch: false }
  );

  setTitle$ = createEffect(
    () =>
      this.rootPageTitle$.pipe(
        tap((rootPageTitle) => this.title.setTitle(rootPageTitle ?? '')),
        switchMap((rootPageTitle) => this.pageBySlugTitle$.pipe(map((pageTitle) => ({ rootPageTitle, pageTitle })))),
        map(({ rootPageTitle, pageTitle }) => (pageTitle ? `${rootPageTitle} | ${pageTitle}` : rootPageTitle)),
        tap((title) => this.title.setTitle(title ?? '')),
        tap((title) => this.lacertaMetaService.setMeta({ [LacertaMetaField.title]: title }))
      ),
    { dispatch: false }
  );

  componentMetaMapping: Map<string, (currentDetailpage: LacertaWagtailPage) => LacertaMetaFields>;

  constructor(
    private lacertaMetaService: LacertaMetaService,
    lacertaMetaConfig: LacertaMetaConfig,
    private title: Title,
    private lacertaCmsNgrxFacade: LacertaCmsNgrxFacade
  ) {
    this.componentMetaMapping = new Map(lacertaMetaConfig.customPageMetaMappings);
  }
}
