import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule, Routes, UrlSerializer } from '@angular/router';
import { buildRoutes } from './route-definitions';
import { SlugRoutePipe } from './slug-route.pipe';
import { LacertaUrlSerializer } from './router-serializer';

@NgModule({
  imports: [CommonModule],
  exports: [SlugRoutePipe, RouterModule],
  declarations: [SlugRoutePipe],
})
export class LacertaRouterModule {
  constructor(private router: Router) {
    // force route reload whenever params change;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  static forRoot(customRoutes: Routes): ModuleWithProviders<LacertaRouterModule> {
    return {
      ngModule: LacertaRouterModule,
      providers: [
        RouterModule.forRoot(buildRoutes(customRoutes), {
          enableTracing: false,
          scrollPositionRestoration: 'enabled',
          relativeLinkResolution: 'legacy',
          anchorScrolling: 'enabled',
        }).providers as Provider[],
        { provide: UrlSerializer, useClass: LacertaUrlSerializer },
      ],
    };
  }
}
