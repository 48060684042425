/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { WagtailConfig } from '../wagtail.config';
import { buildHttpParams } from '../util/http.util';

export interface PagesApiParams {
  search: string;
  search_operator: string;
  type: string;
  slug: string;
  child_of: number;
  descendant_of: number;
  fields: string[];
  show_in_menus: boolean;
  show_on_home: boolean;
  html_path: string;
  offset: number;
  limit: number;
  order: string;
}

export interface PagesResponse<T> {
  meta: {
    total_count: number;
  };
  items: T[];
}

@Injectable({ providedIn: 'root' })
export class WagtailPagesApiService {
  constructor(private http: HttpClient, private wagtailConfig: WagtailConfig) {}

  getById<T>(id: number): Observable<T> {
    return this.http.get<T>(`${this.wagtailConfig.pagesBaseUrl}${id}/`);
  }

  get<T>(pagesApiParams?: Partial<PagesApiParams>): Observable<PagesResponse<T>> {
    const params = buildHttpParams(pagesApiParams);
    return this.http.get<PagesResponse<T>>(`${this.wagtailConfig.pagesBaseUrl}`, { params });
  }
}
