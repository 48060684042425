import { createAction, props } from '@ngrx/store';
import { LacertaWagtailPage } from '../page.model';
import { PagesResponse } from '@lacerta/wagtail';

export interface LoadPagesByTypeParams {
  pageType: string;
  order?: string;
  limit?: number;
  offset?: number;
}

export const cmsInit = createAction('[CMS] Initialization');

export const loadRootPage = createAction('[CMS] Load Root Page', props<{ rootPageType: string }>());
export const loadRootPageSuccess = createAction('[CMS] Load Root Page Success', props<{ page: LacertaWagtailPage }>());

export const loadPageById = createAction('[CMS] Load Page By Id', props<{ id: number }>());
export const loadPageByIdSuccess = createAction('[CMS] Load Page  By Id Success', props<{ page: LacertaWagtailPage }>());

export const loadPageBySlug = createAction('[CMS] Load Page By Slug', props<{ slug: string }>());
export const loadPageBySlugSuccess = createAction('[CMS] Load Page By Slug Success', props<{ page: LacertaWagtailPage }>());

// FIXME: fix lint issue
// eslint-disable-next-line ngrx/prefer-inline-action-props
export const loadPagesByType = createAction('[CMS] Load Pages By Type', props<LoadPagesByTypeParams>());
export const loadPagesByTypeSuccess = createAction(
  '[CMS] Load Pages By Type Success',
  props<{ pageType: string; pages: PagesResponse<LacertaWagtailPage> }>()
);

export const loadMenu = createAction('[CMS] Load Menu', props<{ parentId: number }>());
export const loadMenuSuccess = createAction('[CMS] Load Menu Success', props<{ pages: LacertaWagtailPage[] }>());

export const changeLanguage = createAction('[CMS] Set Language', props<{ language: string }>());

export const failure = createAction('[CMS] Error', props<{ error: unknown }>());
