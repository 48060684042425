import { Type } from '@angular/core';
import { LacertaDynamicTypeComponent } from './dynamic/dynamic-type.component';
import { Routes } from '@angular/router';

export type LacertaComponentDefinition =
  | Type<LacertaDynamicTypeComponent<unknown>>
  | (() => Promise<Type<LacertaDynamicTypeComponent<unknown>>>);
export type LacertaWagtailComponentTypeMapping = ReadonlyArray<readonly [string, LacertaComponentDefinition]>;

export class LacertaCmsConfig {
  rootPageType?: string;
  menuParentPageType?: string;
  wagtailComponentTypeMapping?: LacertaWagtailComponentTypeMapping;
  customRoutes?: Routes;
}
