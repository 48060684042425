import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import * as LacertaCmsActions from './cms.actions';
import { CmsPartialState } from './cms.reducer';
import { distinctUntilChanged, filter, map, skip, switchMap, take, tap } from 'rxjs/operators';
import { PagesResponse, WagtailPagesApiService, WagtailPagesField } from '@lacerta/wagtail';
import { ActivatedRouteSnapshot } from '@angular/router';
import { currentSlugByUrlSegment, filterNullOrUndefined, LacertaRoutePaths } from '@lacerta/util';
import { select } from '@ngrx/store';
import * as LacertaCmsSelectors from './cms.selectors';
import { Observable } from 'rxjs';
import { LacertaWagtailPage } from '../page.model';
import { LacertaMenuApiService } from '../menu/menu-api.service';
import { LacertaCmsConfig } from '../cms.config';
import { routerNavigate } from '@lacerta/ngrx';
import { LacertaTranslationService } from '@lacerta/i18n';
import { LacertaDynamicRoutePageComponent } from '../dynamic/dynamic-route-page.component';

@Injectable()
export class LacertaCmsEffects implements OnInitEffects {
  ngrxOnInitEffects = LacertaCmsActions.cmsInit;

  private menuParentPages$: Observable<LacertaWagtailPage[]> = this.dataPersistence.store
    .select(LacertaCmsSelectors.getDetailPagesByType(), { type: this.lacertaCmsConfig.menuParentPageType })
    .pipe(
      filter((pages) => pages && !!pages.length),
      take(1)
    );
  private currentDetailPageLanguage$ = this.dataPersistence.store.pipe(select(LacertaCmsSelectors.getCurrentDetailPage)).pipe(
    filterNullOrUndefined(),
    map((detailPage: LacertaWagtailPage) => detailPage.language?.code),
    distinctUntilChanged(),
    take(1)
  );
  private currentRoutedComponent$ = this.dataPersistence.store.pipe(
    select(LacertaCmsSelectors.getCurrentRoutedComponent),
    filter((component) => !!component),
    take(1)
  );
  private componentRoutedOninit$ = this.actions$.pipe(
    ofType(LacertaCmsActions.cmsInit),
    switchMap(() => this.currentRoutedComponent$)
  );

  loadRootPageOninit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LacertaCmsActions.cmsInit),
      map(() => this.lacertaCmsConfig.rootPageType),
      filterNullOrUndefined(),
      map((rootPageType: string) => LacertaCmsActions.loadRootPage({ rootPageType }))
    )
  );

  loadMenuParentPages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LacertaCmsActions.cmsInit),
      map(() => this.lacertaCmsConfig.menuParentPageType),
      filterNullOrUndefined(),
      map((pageType) => LacertaCmsActions.loadPagesByType({ pageType }))
    )
  );

  loadPagesByType$ = createEffect(() =>
    this.dataPersistence.fetch(LacertaCmsActions.loadPagesByType, {
      id: (action: ReturnType<typeof LacertaCmsActions.loadPagesByType>) => action.pageType,
      run: (action: ReturnType<typeof LacertaCmsActions.loadPagesByType>) =>
        this.wagtailPagesApiService
          .get<LacertaWagtailPage>({
            type: action.pageType,
            fields: [WagtailPagesField.all],
            ...(action.order && { order: action.order }),
            ...(action.limit && { limit: action.limit }),
            ...(action.offset && { offset: action.offset }),
          })
          .pipe(
            map((pagesResponse: PagesResponse<LacertaWagtailPage>) =>
              LacertaCmsActions.loadPagesByTypeSuccess({ pageType: action.pageType, pages: pagesResponse })
            )
          ),
      onError: (_, error) => LacertaCmsActions.failure({ error }),
    })
  );

  loadDynamicRoutePage$ = createEffect(() =>
    this.dataPersistence.navigation(LacertaDynamicRoutePageComponent, {
      run: (activatedRouteSnapshot: ActivatedRouteSnapshot) =>
        LacertaCmsActions.loadPageBySlug({ slug: currentSlugByUrlSegment(activatedRouteSnapshot.url) }),
    })
  );

  switchToDefaultLanguageOnRefreshDynamicPage$ = createEffect(() =>
    this.componentRoutedOninit$.pipe(
      filter((component) => component === LacertaDynamicRoutePageComponent),
      switchMap(() => this.currentDetailPageLanguage$),
      map((language) => LacertaCmsActions.changeLanguage({ language }))
    )
  );

  switchToDefaultLanguageOnRefreshStaticPage$ = createEffect(() =>
    this.componentRoutedOninit$.pipe(
      filter((component) => component !== LacertaDynamicRoutePageComponent),
      switchMap(() => this.menuParentPages$),
      map((pages) => pages[0].language && pages[0].language.code),
      map((language) => LacertaCmsActions.changeLanguage({ language }))
    )
  );

  loadRootPage$ = createEffect(() =>
    this.dataPersistence.fetch(LacertaCmsActions.loadRootPage, {
      id: (action: ReturnType<typeof LacertaCmsActions.loadRootPage>) => action.rootPageType,
      run: (action: ReturnType<typeof LacertaCmsActions.loadRootPage>) =>
        this.wagtailPagesApiService
          .get<LacertaWagtailPage>({ type: action.rootPageType, fields: [WagtailPagesField.all] })
          .pipe(
            map((pagesResponse: PagesResponse<LacertaWagtailPage>) =>
              LacertaCmsActions.loadRootPageSuccess({ page: pagesResponse.items[0] })
            )
          ),
      onError: (_: ReturnType<typeof LacertaCmsActions.loadRootPage>, error) => LacertaCmsActions.failure({ error }),
    })
  );

  loadPageBySlug$ = createEffect(() =>
    this.dataPersistence.fetch(LacertaCmsActions.loadPageBySlug, {
      id: (action: ReturnType<typeof LacertaCmsActions.loadPageBySlug>) => action.slug,
      run: (action: ReturnType<typeof LacertaCmsActions.loadPageBySlug>) =>
        this.wagtailPagesApiService
          .get<LacertaWagtailPage>({ slug: action.slug, fields: [WagtailPagesField.all] })
          .pipe(
            map((pagesResponse: PagesResponse<LacertaWagtailPage>) =>
              pagesResponse.items.length
                ? LacertaCmsActions.loadPageBySlugSuccess({ page: pagesResponse.items[0] })
                : routerNavigate({ url: LacertaRoutePaths.pageNotFound })
            )
          ),
      onError: (_: ReturnType<typeof LacertaCmsActions.loadPageBySlug>, error) => LacertaCmsActions.failure({ error }),
    })
  );

  loadFullPageAfterSlugSucces$ = createEffect(() =>
    this.dataPersistence.fetch(LacertaCmsActions.loadPageBySlugSuccess, {
      id: (action: ReturnType<typeof LacertaCmsActions.loadPageBySlugSuccess>) => action.page.meta.slug,
      run: (action: ReturnType<typeof LacertaCmsActions.loadPageBySlugSuccess>) => LacertaCmsActions.loadPageById({ id: action.page.id }),
    })
  );

  loadMenuAfterSwitchLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LacertaCmsActions.changeLanguage),
      switchMap((action) => this.menuParentPages$.pipe(map((pages) => ({ pages, lang: action.language })))),
      // map to parentPage in new language if multi-language, or the first one if single-language
      map((data) => (data.pages.length === 1 ? data.pages : data.pages.filter((page) => page.language?.code === data.lang))),
      filter((pages) => !!pages.length),
      map((pages) => pages[0]),
      map((page: LacertaWagtailPage) => page?.id),
      map((parentId) => LacertaCmsActions.loadMenu({ parentId }))
    )
  );

  loadPageById$ = createEffect(() =>
    this.dataPersistence.fetch(LacertaCmsActions.loadPageById, {
      id: (action: ReturnType<typeof LacertaCmsActions.loadPageById>) => action.id,
      run: (action: ReturnType<typeof LacertaCmsActions.loadPageById>) =>
        this.wagtailPagesApiService
          .getById<LacertaWagtailPage>(action.id)
          .pipe(map((page: LacertaWagtailPage) => LacertaCmsActions.loadPageByIdSuccess({ page }))),
      onError: (_: ReturnType<typeof LacertaCmsActions.loadPageById>, error) => LacertaCmsActions.failure({ error }),
    })
  );

  loadMenu$ = createEffect(() =>
    this.dataPersistence.fetch(LacertaCmsActions.loadMenu, {
      id: (action: ReturnType<typeof LacertaCmsActions.loadMenu>) => action.parentId,
      run: (action: ReturnType<typeof LacertaCmsActions.loadMenu>) =>
        this.lacertaMenuApiService
          // eslint-disable-next-line @typescript-eslint/naming-convention
          .get({ descendant_of: action.parentId })
          .pipe(map((pages) => LacertaCmsActions.loadMenuSuccess({ pages: pages.items }))),
      onError: (_: ReturnType<typeof LacertaCmsActions.loadMenu>, error) => LacertaCmsActions.failure({ error }),
    })
  );

  navigateToTranslatedSlugOnLangSwitch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LacertaCmsActions.changeLanguage),
      filter((lang) => !!lang),
      distinctUntilChanged(),
      skip(1),
      switchMap((action) =>
        this.dataPersistence.store.pipe(
          select(LacertaCmsSelectors.getCurrentRoutedComponent),
          take(1),
          filter((component) => component === LacertaDynamicRoutePageComponent),
          map(() => action)
        )
      ),
      switchMap((action) =>
        this.dataPersistence.store.pipe(
          select(LacertaCmsSelectors.getTranslatedPageSlug(), { language: action.language }),
          take(1),
          map((slug) => ({ language: action.language, slug }))
        )
      ),
      switchMap((data) =>
        this.dataPersistence.store.pipe(
          select(LacertaCmsSelectors.getDetailPageByTypeAndLanguage(), {
            type: this.lacertaCmsConfig.menuParentPageType,
            language: data.language,
          }),
          take(1),
          map((translatedHomePage: LacertaWagtailPage) => ({
            translatedHomePageSlug: translatedHomePage && translatedHomePage.meta.slug,
            slug: data.slug,
          }))
        )
      ),
      map((data) => data.slug || data.translatedHomePageSlug),
      map((slug) => routerNavigate({ url: slug }))
    )
  );

  setClientSideLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LacertaCmsActions.changeLanguage),
        filter((action) => !!action.language),
        tap((action) => this.translationService.setLanguage(action.language))
      ),
    { dispatch: false }
  );

  setClientSideAvailableLanguages$ = createEffect(
    () =>
      this.dataPersistence.store.pipe(
        select(LacertaCmsSelectors.getAllLanguages(), { type: this.lacertaCmsConfig.menuParentPageType }),
        distinctUntilChanged(
          (languages1, languages2) =>
            languages1.length === languages2.length && languages1.every((value, index) => value === languages2[index])
        ),
        filter((value) => value && !!value.length),
        tap((languages) => this.translationService.setAvailableLanguages(languages))
      ),
    { dispatch: false }
  );

  error$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LacertaCmsActions.failure),
        tap((action) => console.error('Error', action.error))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<CmsPartialState>,
    private wagtailPagesApiService: WagtailPagesApiService,
    private lacertaCmsConfig: LacertaCmsConfig,
    private lacertaMenuApiService: LacertaMenuApiService,
    private translationService: LacertaTranslationService
  ) {}
}
