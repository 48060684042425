import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WijZijnComponent } from './wij-zijn.component';
import { SharedModule } from '../../shared/shared.module';
import { SnakeModule } from '../snake/snake.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [WijZijnComponent],
  imports: [CommonModule, SharedModule, SnakeModule, FontAwesomeModule],
  exports: [WijZijnComponent],
})
export class WijZijnModule {}
