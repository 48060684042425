import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EvenPipe } from './even.pipe';
import { OddPipe } from './odd.pipe';

@NgModule({
  declarations: [EvenPipe, OddPipe],
  imports: [CommonModule],
  exports: [EvenPipe, OddPipe],
})
export class LacertaEvenOddModule {}
