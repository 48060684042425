import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WagtailConfig } from './wagtail.config';
import { HttpClientModule } from '@angular/common/http';
import { preventReimport } from '@lacerta/util';

@NgModule({
  imports: [CommonModule, HttpClientModule],
})
export class LacertaWagtailModule {
  constructor(@Optional() @SkipSelf() parentModule: LacertaWagtailModule) {
    preventReimport(parentModule, 'WagtailModule');
  }

  static forRoot(config: WagtailConfig): ModuleWithProviders<LacertaWagtailModule> {
    return {
      ngModule: LacertaWagtailModule,
      providers: [{ provide: WagtailConfig, useValue: config }],
    };
  }
}
