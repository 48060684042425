import { Component } from '@angular/core';
import { LacertaDynamicComponentService } from '../../dynamic/dynamic-component.service';

export const pageNotFoundBlockType = 'page-not-found-block' as const;

@Component({
  template: `
    <div fxLayout="column" fxLayoutAlign="center" fxFlexFill>
      <lacerta-dynamic-block
        *ngIf="lacertaDynamicComponentService.hasMappingForType(pageNotFoundBlockType); else defaultPageNotFound"
        [blockType]="pageNotFoundBlockType"
      ></lacerta-dynamic-block>
      <ng-template #defaultPageNotFound>
        <section class="hero is-large is-light">
          <div class="hero-body">
            <div class="container">
              <h1 class="title">Page not found</h1>
              <h2 class="subtitle">This page does not exist.</h2>
            </div>
          </div>
        </section>
      </ng-template>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        height: 100%;
      }
    `,
  ],
})
export class LacertaPageNotFoundComponent {
  pageNotFoundBlockType = pageNotFoundBlockType;

  constructor(public readonly lacertaDynamicComponentService: LacertaDynamicComponentService) {}
}
