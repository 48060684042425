import { Injectable } from '@angular/core';
import { allMetaFieldDefinitions, LacertaMetaFields } from './meta.model';
import { mapImageToMetaValues } from './meta.util';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { objectKeys } from '@lacerta/util';

@Injectable()
export class LacertaMetaService {
  constructor(private meta: Meta) {}

  public setImage = (url: string, width: number, height: number) => this.setMeta(mapImageToMetaValues(url, width, height));

  public setMeta(metaFields: LacertaMetaFields) {
    allMetaFieldDefinitions.forEach((fieldDefinitions) =>
      objectKeys(fieldDefinitions)
        .filter((metaFieldKey) => metaFieldKey in metaFields)
        .map((metaFieldKey) => ({ ...fieldDefinitions[metaFieldKey], content: metaFields[metaFieldKey] }))
        .forEach((metaDefinition) => this.meta.updateTag(metaDefinition as MetaDefinition))
    );
  }
}
