import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

export enum Events {
  render,
  play,
  ai,
  scored,
  started,
}

@Injectable()
export class SnakeService {
  private subject$ = new Subject<unknown>();

  render() {
    this.subject$.next(Events.render);
  }

  play(ai = false) {
    this.subject$.next(ai ? Events.ai : Events.play);
  }

  started() {
    this.subject$.next(Events.started);
  }

  score() {
    this.subject$.next(Events.scored);
  }

  get events$() {
    return this.subject$.asObservable();
  }
}
