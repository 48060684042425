import { PlayField } from './play-field.model';
import { Snake } from './snake';
import { Zone } from './zone.model';

interface DrawImageRotParams {
  ctx: CanvasRenderingContext2D;
  img: CanvasImageSource;
  xValue: number;
  yValue: number;
  width: number;
  height: number;
  deg: number;
}

const straightAngle = 180;
const revolutionAngle = 0;
const rightAngle = 90;
const reflexAngle = 270;
const maxRuns = 20;
const resetPosition = -10;
const minNrRunsForPlayfield = 10;

const rotations: number[] = [revolutionAngle, rightAngle, straightAngle, reflexAngle];

export class Fruit {
  constructor(playfield: PlayField, zone: Zone | null = null) {
    this.playfield = playfield;
    this.zone = zone;
  }

  xValue = 0;
  yValue = 0;
  width = 1;
  height = 1;
  rotation = 0;
  type = 0;
  private playfield: PlayField;
  private zone: Zone | null;
  private alt = false;

  static drawImageRot({ ctx, img, xValue, yValue, width, height, deg }: DrawImageRotParams) {
    // Convert degrees to radian
    const rad = (deg * Math.PI) / straightAngle;

    // Set the origin to the center of the image
    // noinspection TypeScriptValidateJSTypes
    ctx.translate(xValue + width / 2, yValue + height / 2);

    // Rotate the canvas around the origin
    ctx.rotate(rad);

    // draw the image
    ctx.drawImage(img, (width / 2) * -1, (height / 2) * -1, width, height);

    // reset the canvas
    ctx.rotate(rad * -1);
    // noinspection TypeScriptValidateJSTypes
    ctx.translate((xValue + width / 2) * -1, (yValue + height / 2) * -1);
  }

  private static randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  check(snake: Snake, regenerate = true) {
    if (this.xValue > this.playfield.cols || this.yValue > this.playfield.rows) {
      this.generatePos(snake);
    }

    let score = 0;
    // Check for snake collisions
    if (this.collides(snake)) {
      snake.addToTail();
      score++;
      if (regenerate) {
        this.generatePos(snake);
      } else {
        this.playfield.fruits.splice(this.playfield.fruits.indexOf(this), 1);
      }
    }

    return score;
  }

  generatePos(snake: Snake, run = 0) {
    if (this.zone && run < minNrRunsForPlayfield) {
      this.xValue = Math.floor(Math.random() * this.zone.width) + this.zone.xValue;
      this.yValue = Math.floor(Math.random() * this.zone.height) + this.zone.yValue;
    } else {
      this.xValue = Math.floor(Math.random() * this.playfield.cols);
      this.yValue = Math.floor(Math.random() * this.playfield.rows);
    }

    const nrCases = 4;
    const probableType = Fruit.randomIntFromInterval(0, nrCases - 1);
    const randomBoolean = Math.round(Math.random()) === 0;

    this.width = 1;
    this.height = 1;
    this.rotation = 0;
    this.alt = Math.round(Math.random()) === 0;
    this.type = probableType;

    const value = probableType * 2;

    switch (probableType) {
      case 1:
        if (randomBoolean) {
          this.width = value;
        } else {
          this.height = value;
        }
        break;
      case 2:
        if (randomBoolean) {
          this.width = value;
        } else {
          this.height = value;
        }
        break;
      case 3:
        this.width = 2;
        this.height = 2;
        this.rotation = rotations[Math.floor(Math.random() * rotations.length)];
        break;
    }
    if (this.collides(snake) || this.overlaps() || this.isInForbiddenZone()) {
      if (run + 1 > maxRuns) {
        this.xValue = resetPosition;
        this.yValue = resetPosition;
        this.playfield.fruits.splice(this.playfield.fruits.indexOf(this), 1);
      } else {
        this.generatePos(snake, run + 1);
      }
    } else {
      this.zone = null;
    }
  }

  overlaps() {
    for (const fruit of this.playfield.fruits) {
      if (fruit && fruit !== this && fruit.xValue && fruit.yValue) {
        for (let otherWidth = 0; otherWidth < fruit.width; otherWidth++) {
          for (let otherHeight = 0; otherHeight < fruit.height; otherHeight++) {
            for (let thisWidth = 0; thisWidth < this.width; thisWidth++) {
              for (let thisHeight = 0; thisHeight < this.height; thisHeight++) {
                if (fruit.xValue + otherWidth === this.xValue + thisWidth && fruit.yValue + otherHeight === this.yValue + thisHeight) {
                  return true;
                }
              }
            }
          }
        }
      }
    }
    return false;
  }

  isInForbiddenZone() {
    const fieldRect = {
      left: this.playfield.offsetX + this.xValue * this.playfield.scale,
      top: this.playfield.offsetY + this.yValue * this.playfield.scale,
      right: this.playfield.offsetX + this.xValue * this.playfield.scale + this.width * this.playfield.scale,
      bottom: this.playfield.offsetY + this.yValue * this.playfield.scale + this.height * this.playfield.scale,
    };
    for (const zone of this.playfield.forbiddenZones) {
      const zoneRect = zone.getBoundingClientRect();
      const overlap = !(
        zoneRect.right < fieldRect.left ||
        zoneRect.left > fieldRect.right ||
        zoneRect.bottom < fieldRect.top ||
        zoneRect.top > fieldRect.bottom
      );

      if (overlap) {
        return true;
      }
    }

    return false;
  }

  collides(snake: Snake) {
    return snake.tail.some((tailElement) => this.getColumns().includes(tailElement[0]) && this.getRows().includes(tailElement[1]));
  }

  getRows() {
    return [...Array(this.height).keys()].map((height) => this.yValue + height);
  }

  getColumns() {
    return [...new Array(this.width).keys()].map((width) => this.xValue + width);
  }

  draw(ctx: CanvasRenderingContext2D) {
    if (this.type === 3) {
      const image = this.alt ? this.playfield.arc : this.playfield.arcAlt;
      Fruit.drawImageRot({
        ctx,
        img: image,
        xValue: this.xValue * this.playfield.scale,
        yValue: this.yValue * this.playfield.scale,
        width: this.playfield.scale * this.width,
        height: this.playfield.scale * this.height,
        deg: this.rotation,
      });
    } else {
      ctx.fillStyle = this.alt ? this.playfield.color : this.playfield.altColor;
      ctx.fillRect(
        this.xValue * this.playfield.scale,
        this.yValue * this.playfield.scale,
        this.playfield.scale * this.width,
        this.playfield.scale * this.height
      );
    }
  }
}
