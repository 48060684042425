import { NgModule } from '@angular/core';
import { RichTextComponent } from './rich-text-block/rich-text-block-component.component';
import { TableBlockComponent } from './table-block/table-block-component.component';
import { WhitespaceBlockComponent } from './whitespace-block/whitespace-block.component';
import { LacertaCmsTransformersModule } from '../content-transformation';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [LacertaCmsTransformersModule, CommonModule],
  declarations: [RichTextComponent, TableBlockComponent, WhitespaceBlockComponent],
  exports: [RichTextComponent, TableBlockComponent, WhitespaceBlockComponent],
})
export class LacertaCmsBlocksModule {}
