import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BulmaFormControlComponent } from './form-control.component';
import { LacertaFormModule } from '@lacerta/form';
import { NgxCaptchaModule } from 'ngx-captcha';

@NgModule({
  declarations: [BulmaFormControlComponent],
  imports: [CommonModule, LacertaFormModule, NgxCaptchaModule],
  exports: [BulmaFormControlComponent],
})
export class BulmaFormModule {}
