import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RiffProjectField, RiffProjectPage } from './project.model';
import { PagesResponse, WagtailPagesApiService } from '@lacerta/wagtail';
import { RiffWagtailPageType } from '../page/page.model';

@Injectable()
export class ProjectService {
  constructor(private pagesService: WagtailPagesApiService) {}

  getByParentId(parentId: number): Observable<RiffProjectPage[]> {
    return this.pagesService
      .get<RiffProjectPage>({
        type: RiffWagtailPageType.projectDetail,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        child_of: parentId,
        fields: [RiffProjectField.year, RiffProjectField.tag_line, RiffProjectField.thumbnail],
      })
      .pipe(map((pagesResponse: PagesResponse<RiffProjectPage>) => pagesResponse.items));
  }
}
