import { NgModule, Optional, SkipSelf } from '@angular/core';
import { preventReimport } from '@lacerta/util';
import { StoreModule } from '@ngrx/store';
import { CMS_FEATURE_KEY, reducer } from './cms.reducer';
import { EffectsModule } from '@ngrx/effects';
import { LacertaCmsEffects } from './cms.effects';
import { LacertaTranslationModule } from '@lacerta/i18n';
import { LacertaMenuModule } from '../menu';

@NgModule({
  imports: [
    StoreModule.forFeature(CMS_FEATURE_KEY, reducer),
    EffectsModule.forFeature([LacertaCmsEffects]),
    LacertaTranslationModule,
    LacertaMenuModule,
  ],
})
export class LacertaCmsNgrxModule {
  constructor(@Optional() @SkipSelf() parentModule: LacertaCmsNgrxModule) {
    preventReimport(parentModule, 'CmsDataAccessModule');
  }
}
