import { Injectable } from '@angular/core';
import { HashMap, TranslocoService } from '@ngneat/transloco';
import { mapTo, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LacertaTranslationService {
  constructor(private translocoService: TranslocoService) {}

  setLanguage(language: string) {
    this.translocoService
      .load(language)
      .pipe(
        take(1),
        mapTo([language, ...(this.translocoService.getAvailableLangs() as string[])]),
        tap((availableLangs) => this.translocoService.setAvailableLangs(availableLangs)),
        tap(() => this.translocoService.setActiveLang(language))
      )
      .subscribe();
  }

  setAvailableLanguages(languages: string[]) {
    this.translocoService.setAvailableLangs(languages);
  }

  selectTranslationObject(key: string) {
    return this.translocoService.selectTranslateObject(key);
  }

  selectTranslateObject(keyParams: HashMap | Map<string, HashMap>) {
    return this.translocoService.selectTranslateObject(keyParams);
  }

  selectTranslate(key: string) {
    return this.translocoService.selectTranslate(key);
  }

  translate(key: string | string[]) {
    return this.translocoService.translate(key);
  }
}
