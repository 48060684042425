import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WagtailStreamField } from '@lacerta/wagtail';
import { LacertaMapper } from '@lacerta/util';
import { LacertaEmbedBlock, LacertaImageChooserBlock } from '@lacerta/cms';

const nextTimeout = 3000;
const previousNextTimeout = 5000;

@Component({
  selector: 'riff-project-gallery',
  template: `
    <div class="container">
      <ng-container *ngIf="currentType === 'image' && current">
        <div
          class="image columns"
          (swipeleft)="previous()"
          (swiperight)="next()"
          [lacertaBackgroundImage]="current | lacertaMapper: toImageChooserBlock | asRenderedUiImage: 'large'"
        >
          <div class="previous" (click)="previous()" title="previous"></div>
          <div class="next" (click)="next()" title="next"></div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentType === 'video' && current">
        <div class="video" [innerHtml]="current | lacertaMapper: toLacertaEmbedBlockHtml | sanitizeHtml"></div>
      </ng-container>
    </div>

    <img *ngIf="preload" class="preload" [src]="preload.renditions['large'].src" alt="preload" />
  `,
  styleUrls: ['./project-gallery.component.scss'],
})
export class ProjectGalleryComponent implements OnInit, OnDestroy {
  @Input() images: WagtailStreamField<LacertaImageChooserBlock | LacertaEmbedBlock>[];

  current: LacertaImageChooserBlock | LacertaEmbedBlock | null;
  preload: LacertaImageChooserBlock | null;
  currentType: string;
  private currentIndex: number;
  private timer: number;

  toImageChooserBlock: LacertaMapper<LacertaImageChooserBlock | LacertaEmbedBlock, LacertaImageChooserBlock> = (
    value: LacertaImageChooserBlock | LacertaEmbedBlock
  ) => <LacertaImageChooserBlock>value;
  toLacertaEmbedBlockHtml: LacertaMapper<LacertaImageChooserBlock | LacertaEmbedBlock, string> = (
    value: LacertaImageChooserBlock | LacertaEmbedBlock
  ) => (<LacertaEmbedBlock>value).html;

  ngOnInit(): void {
    this.next();
  }

  previous(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    if (this.images.length === 0) {
      this.current = null;
      this.currentIndex = -1;
    }
    if (!this.current) {
      this.currentIndex = 0;
    } else {
      this.currentIndex--;
      if (this.currentIndex < 0) {
        this.currentIndex = this.images.length - 1;
      }
    }

    this.current = this.images[this.currentIndex].value;
    this.currentType = this.images[this.currentIndex].type;

    this.timer = window.setTimeout(() => this.next(), previousNextTimeout);
  }

  next(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.currentIndex = this.getNextIndex(this.currentIndex);

    if (this.currentIndex >= 0) {
      this.current = this.images[this.currentIndex].value;
      this.currentType = this.images[this.currentIndex].type;
    }

    const nextIndex = this.getNextIndex(this.currentIndex);
    if (nextIndex >= 0 && this.images[nextIndex].type === 'image') {
      this.preload = <LacertaImageChooserBlock>this.images[nextIndex].value;
    } else {
      this.preload = null;
    }

    this.timer = window.setTimeout(() => this.next(), nextTimeout);
  }

  private getNextIndex(start: number) {
    let index = start;
    if (this.images.length === 0) {
      index = -1;
    }
    if (!this.current) {
      index = 0;
    } else {
      index++;
      if (index >= this.images.length) {
        index = 0;
      }
    }
    return index;
  }

  ngOnDestroy(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
}
