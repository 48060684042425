import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as LacertaCmsSelectors from './cms.selectors';
import * as LacertaCmsActions from './cms.actions';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LacertaCmsConfig } from '../cms.config';
import { filterNullOrUndefined, LacertaRoutePaths } from '@lacerta/util';
import { WagtailMenuPage } from '@lacerta/wagtail';
import { convertPagesToMenu } from '../menu/menu.util';

@Injectable({ providedIn: 'root' })
export class LacertaCmsNgrxFacade {
  rootPage$ = this.store$.select(LacertaCmsSelectors.getRootPage).pipe(filterNullOrUndefined());

  currentSummaryPage$ = this.store$.select(LacertaCmsSelectors.getCurrentSummaryPage).pipe(filterNullOrUndefined());
  currentDetailPage$ = this.store$.select(LacertaCmsSelectors.getCurrentDetailPage).pipe(filterNullOrUndefined());
  currentSlug$ = this.store$.pipe(select(LacertaCmsSelectors.getCurrentSlug));
  availableLanguages$: Observable<string[]> = this.store$
    .select(LacertaCmsSelectors.getAvailableLanguages(), { type: this.lacertaCmsConfig.menuParentPageType })
    .pipe(filter((languages) => !!languages?.length));
  homePage$ = this.store$.select(LacertaCmsSelectors.getDetailPageByTypeInCurrentLanguage(), {
    type: this.lacertaCmsConfig.menuParentPageType,
  });
  homePageId$ = this.homePage$.pipe(map((page) => page?.id));
  homePageUrl$ = this.homePage$.pipe(
    map((page) => page && page.meta && page.meta.slug),
    map((slug) => slug || LacertaRoutePaths.home)
  );

  menu$: Observable<WagtailMenuPage[]> = this.store$.select(LacertaCmsSelectors.getMenuSummaryPages).pipe(
    filterNullOrUndefined(),
    withLatestFrom(this.homePageId$),
    map(([pages, homeId]) => convertPagesToMenu(pages, homeId))
  );

  constructor(private store$: Store, private lacertaCmsConfig: LacertaCmsConfig) {}

  selectDetailPageById = (id: number) => this.store$.select(LacertaCmsSelectors.getDetailPageById(), { id });
  selectDetailPagesByType = (type: string) => this.store$.select(LacertaCmsSelectors.getDetailPagesByType(), { type });
  selectDetailPagesTotalCountByType = (type: string) => this.store$.select(LacertaCmsSelectors.getDetailPagesTotalCountByType(), { type });
  selectMenuPagesByType = (type: string) => this.store$.select(LacertaCmsSelectors.getMenuPagesByType(), { type });

  loadPageById = (id: number) => this.store$.dispatch(LacertaCmsActions.loadPageById({ id }));
  loadPageByType = ({ pageType, order, limit, offset }: LacertaCmsActions.LoadPagesByTypeParams) =>
    this.store$.dispatch(LacertaCmsActions.loadPagesByType({ pageType, order, limit, offset }));
  switchLanguage = (language: string) => this.store$.dispatch(LacertaCmsActions.changeLanguage({ language }));
}
