import { LacertaWagtailPage } from '@lacerta/cms';
import { LacertaMetaField, LacertaMetaFields } from './meta.model';
import { stripHtml, truncate } from '@lacerta/util';

const mapLacertaWagtailPageToTitle = (page?: LacertaWagtailPage) => page?.meta.seo_title || page?.title;

export const mapRootPageToMetaValues = (page?: LacertaWagtailPage): LacertaMetaFields => ({
  ...mapContextToMetaValues(page),
  [LacertaMetaField.appName]: mapLacertaWagtailPageToTitle(page),
});

export const mapContextToMetaValues = (page?: LacertaWagtailPage): LacertaMetaFields => {
  const searchImage = page?.meta.search_image;
  return {
    [LacertaMetaField.url]: `${document.location}`,
    [LacertaMetaField.twitterCard]: 'summary',
    ...(page?.meta && { [LacertaMetaField.description]: page.meta.search_description }),
    ...(searchImage && mapImageToMetaValues(searchImage.url, searchImage.width, searchImage.height)),
  };
};

export const mapImageToMetaValues: (url: string, width: number, height: number) => LacertaMetaFields = (
  url: string,
  width: number,
  height: number
) => ({
  [LacertaMetaField.image]: `${window.location.origin}${url}`,
  ...(width && { [LacertaMetaField.imageWidth]: width.toString() }),
  ...(height && { [LacertaMetaField.imageHeight]: height.toString() }),
});

export const filterEmptyValues = (metaFields: LacertaMetaFields) =>
  Object.entries(metaFields).reduce(
    (result, [metaValueKey, metaValue]) => (metaValue && metaValue && metaValue.length ? { ...result, [metaValueKey]: metaValue } : result),
    {} as LacertaMetaFields
  );

const metaMaxLength = 200;
export const mapHtmlOnMetaValue = (html: string) => `${truncate(stripHtml(html), metaMaxLength)}`;
