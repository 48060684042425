<section [class]="projectType?.meta?.slug">
  <div class="background"></div>
  <div class="snake white-theme"><riff-snake [amount]="6" [waitForIt]="true"></riff-snake></div>
  <div class="container overlay" #overlay>
    <header class="nosnake">
      <h1>{{ projectType?.title }}</h1>
      <div *ngIf="(currentDetailPage$ | async)?.intro" class="intro" [innerHtml]="(currentDetailPage$ | async)?.intro"></div>
    </header>

    <div *ngIf="selected?.thumbnail as thumbnail" class="image nosnake">
      <a routerLink="{{ selected?.meta?.slug }}">
        <div class="cover"></div>
        <div class="thumbnail" [ngStyle]="{ 'background-image': 'url(' + thumbnail.url + ')' }"></div>
        <!-- <img [src]="selected.thumbnail.url" [alt]="selected.title"/> -->
      </a>
    </div>
  </div>
  <div class="container projects" [ngStyle]="{ 'margin-top': introHeight + 'px', 'margin-bottom': projectsBottomMargin + 'px' }" #projects>
    <div class="arrow nosnake" [ngStyle]="{ top: introHeight + 'px' }" #arrow></div>
    <div class="padding nosnake"></div>
    <ng-container *ngFor="let project of projects$ | async">
      <div
        class="project jump-2 nosnake"
        [attr.data-slug]="project.meta.slug"
        [ngClass]="[project.meta.slug === selected?.meta?.slug ? 'selected' : '']"
      >
        <a routerLink="{{ project.meta.slug }}">
          <img *ngIf="project.thumbnail" [src]="project.thumbnail.url" class="preload" alt="thumbnail" />
          <div>
            <span class="title">{{ project.title }}</span> <span class="tag_line" *ngIf="project.tag_line"> - {{ project.tag_line }}</span>
          </div>
          <div class="year">{{ project.year }}</div>
          <div *ngIf="project?.thumbnail" class="mobile image nosnake">
            <div class="cover"></div>
            <div class="thumbnail" [ngStyle]="{ 'background-image': 'url(' + project.thumbnail.url + ')' }"></div>
            <!-- <img [src]="selected.thumbnail.url" [alt]="selected.title"/> -->
          </div>
        </a>
      </div>
    </ng-container>
  </div>
</section>
<footer><a class="contact nosnake" routerLink="/contact">contact</a></footer>
