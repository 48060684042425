import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LacertaDynamicRoutePageComponent } from './dynamic-route-page.component';
import { DynamicAttributesModule, DynamicModule } from 'ng-dynamic-component';
import { LacertaDynamicPageWithMetaComponent } from './dynamic-page-with-meta.component';
import { LacertaDynamicPageComponent } from './dynamic-page.component';
import { UnknownBlockComponent } from './unknown-block.component';
import { MapToComponentPipe } from './map-to-component.pipe';
import { LacertaStreamFieldComponent } from './stream-field.component';
import { LacertaDynamicBlockComponent } from './dynamic-block.component';

@NgModule({
  declarations: [
    LacertaDynamicRoutePageComponent,
    LacertaDynamicPageWithMetaComponent,
    LacertaDynamicPageComponent,
    UnknownBlockComponent,
    MapToComponentPipe,
    LacertaStreamFieldComponent,
    LacertaDynamicBlockComponent,
  ],
  imports: [CommonModule, DynamicModule, DynamicAttributesModule],
  exports: [
    LacertaDynamicPageWithMetaComponent,
    LacertaDynamicPageComponent,
    MapToComponentPipe,
    LacertaStreamFieldComponent,
    LacertaDynamicBlockComponent,
  ],
})
export class LacertaDynamicModule {}
