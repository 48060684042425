import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldComponent } from './field/field.component';
import { LacertaBulmaNavbarModule } from './navbar/navbar.module';
import { BulmaFormModule } from './form/form.module';

@NgModule({
  imports: [CommonModule, LacertaBulmaNavbarModule, BulmaFormModule],
  declarations: [FieldComponent],
  exports: [FieldComponent],
})
export class LacertaBulmaModule {}
