import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { buildHttpParams, PagesApiParams, PagesResponse } from '@lacerta/wagtail';
import { LacertaWagtailPage } from '../page.model';
import { Observable } from 'rxjs';

@Injectable()
export class LacertaMenuApiService {
  constructor(private http: HttpClient) {}

  get(pagesApiParams?: Partial<PagesApiParams>): Observable<PagesResponse<LacertaWagtailPage>> {
    const params = buildHttpParams(pagesApiParams);
    return this.http.get<PagesResponse<LacertaWagtailPage>>('api/v2/menus', { params });
  }
}
