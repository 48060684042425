import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LacertaBulmaNavbarComponent } from './navbar.component';
import { NavbarItemsComponent } from './navbar-items/navbar-items.component';
import { RouterModule } from '@angular/router';
import { LacertaRouterModule } from '@lacerta/cms';

@NgModule({
  declarations: [LacertaBulmaNavbarComponent, NavbarItemsComponent],
  imports: [CommonModule, RouterModule, LacertaRouterModule],
  exports: [LacertaBulmaNavbarComponent],
})
export class LacertaBulmaNavbarModule {}
