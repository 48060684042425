import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RiffWagtailModule } from './riff-wagtail/riff-wagtail.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CommonModule } from '@angular/common';
import { HeaderModule } from './header/header.module';
import { FooterModule } from './footer/footer.module';
import { RiffWagtailWijZijnPageComponent } from './riff-wagtail/wij-zijn/wij-zijn.component';
import { RiffWagtailHomeComponent } from './riff-wagtail/home/home.component';
import { RiffWagtailProjectTypePageComponent } from './riff-wagtail/project/project-type.component';
import { RiffWagtailProjectPageComponent } from './riff-wagtail/project/project.component';
import { RiffWagtailContactPageComponent } from './riff-wagtail/contact/contact.component';
import { LacertaCoreModule } from '@lacerta/core';
import { LacertaRoutePaths, preventReimport } from '@lacerta/util';
import { LacertaWagtailComponentTypeMapping } from '@lacerta/cms';
import { RouterModule } from '@angular/router';
import { RiffWagtailPageType } from './riff-wagtail/page/page.model';
import { environment } from '../../environments/environment';

const wagtailComponentTypeMapping: LacertaWagtailComponentTypeMapping = [
  [RiffWagtailPageType.wijZijn, RiffWagtailWijZijnPageComponent],
  [RiffWagtailPageType.home, RiffWagtailHomeComponent],
  [RiffWagtailPageType.projectIndex, RiffWagtailProjectTypePageComponent],
  [RiffWagtailPageType.projectType, RiffWagtailProjectTypePageComponent],
  [RiffWagtailPageType.project, RiffWagtailProjectPageComponent],
  [RiffWagtailPageType.projectDetail, RiffWagtailProjectPageComponent],
  [RiffWagtailPageType.contact, RiffWagtailContactPageComponent],
];

@NgModule({
  imports: [
    CommonModule,
    RiffWagtailModule,
    LacertaCoreModule.forRoot({
      metaConfig: {},
      wagtailComponentTypeMapping,
      customRoutes: [
        {
          path: 'countdown',
          redirectTo: LacertaRoutePaths.home,
        },
      ],
      rootPageType: RiffWagtailPageType.root,
      menuParentPageType: RiffWagtailPageType.root,
      environment,
    }),
    HeaderModule,
    FooterModule,
  ],
  exports: [HeaderComponent, FooterComponent, RouterModule],
})
export class RiffCoreModule {
  constructor(@Optional() @SkipSelf() parentModule: RiffCoreModule) {
    preventReimport(parentModule, 'RiffCoreModule');
  }
}
