import { Pipe, PipeTransform } from '@angular/core';
import { LacertaImageChooserBlock } from '../block.model';
import { LacertaUiImage } from '@lacerta/ui';
import { mapImageChooserBlockToUiImage } from './image.util';

@Pipe({
  name: 'asRenderedUiImages',
})
export class AsRenderedUiImagesPipe implements PipeTransform {
  transform(imageBlocks: LacertaImageChooserBlock[], rendition?: string): LacertaUiImage[] {
    return imageBlocks?.map((imageBlock) => mapImageChooserBlockToUiImage(imageBlock, rendition));
  }
}

@Pipe({
  name: 'asRenderedUiImage',
})
export class AsRenderedUiImagePipe implements PipeTransform {
  transform(imageBlock?: LacertaImageChooserBlock, rendition?: string): LacertaUiImage | undefined {
    return imageBlock ? mapImageChooserBlockToUiImage(imageBlock, rendition) : undefined;
  }
}
