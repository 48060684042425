import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { RiffProjectIndexPage, RiffProjectPage } from '../../../core/riff-wagtail/project/project.model';
import { ProjectService } from '../../../core/riff-wagtail/project/project.service';
import { map } from 'rxjs/operators';
import { SnakeService } from '../../snake/snake.service';
import { LacertaCmsNgrxFacade } from '@lacerta/cms';

const renderTimeout = 50;
const minProjectsBottomMargin = 50;

@Component({
  selector: 'riff-project-type-page',
  templateUrl: './project-type.component.html',
  styleUrls: ['./project-type.component.scss'],
})
export class ProjectTypeComponent implements OnInit {
  constructor(
    private lacertaCmsNgrxFacade: LacertaCmsNgrxFacade,
    private projectService: ProjectService,
    private snakeService: SnakeService
  ) {}
  @ViewChild('overlay', { read: ElementRef, static: true }) overlayElem: ElementRef;
  @ViewChild('projects', { read: ElementRef, static: true }) projectElems: ElementRef;
  @ViewChild('arrow', { read: ElementRef, static: true }) arrowElem: ElementRef;

  @Input() projectType?: RiffProjectIndexPage;

  currentDetailPage$: Observable<RiffProjectIndexPage> = this.lacertaCmsNgrxFacade.currentDetailPage$.pipe(
    map((page) => page as RiffProjectIndexPage)
  );
  projects$: Observable<RiffProjectPage[]>;

  projects: RiffProjectPage[];
  introHeight = 0;
  projectsBottomMargin = 0;
  selected: RiffProjectPage | undefined | null;

  ngOnInit() {
    if (this.projectType?.id) {
      this.projects$ = this.projectService.getByParentId(this.projectType.id);
    }

    this.projects$.subscribe({
      next: (value) => {
        this.projects = value;
        this.selected = this.projects[0];
        this.calculateProjectsPadding();
      },
    });

    this.currentDetailPage$.subscribe({
      next: () => {
        this.introHeight = this.overlayElem.nativeElement.offsetHeight;
      },
    });

    combineLatest([this.currentDetailPage$, this.projects$]).subscribe({
      next: () => {
        setTimeout(() => {
          this.snakeService.render();
        }, renderTimeout);
      },
    });
  }

  @HostListener('window:scroll', [])
  findSelected() {
    this.calculateProjectsPadding();
    const arrowRect = this.arrowElem.nativeElement.getBoundingClientRect();
    const arrowY = arrowRect.top + arrowRect.height / 2;

    for (const projectElem of this.projectElems.nativeElement.querySelectorAll('.project')) {
      const rect = projectElem.getBoundingClientRect();
      const slug = projectElem.getAttribute('data-slug');
      if (arrowY > rect.top && arrowY < rect.top + rect.height) {
        this.selected = this.projects.find((project) => project.meta.slug === slug);
      } else if (this.selected && this.selected.meta.slug === slug) {
        this.selected = null;
      }
    }
  }

  calculateProjectsPadding() {
    const arrowRect = this.arrowElem.nativeElement.getBoundingClientRect();
    this.projectsBottomMargin = Math.max(minProjectsBottomMargin, window.innerHeight - arrowRect.top - arrowRect.height);
  }
}
