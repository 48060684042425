import { Component } from '@angular/core';
import { LacertaDynamicTypeComponent } from './dynamic-type.component';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LacertaWagtailPage } from '../page.model';
import { LacertaCmsNgrxFacade } from '../+state';

@Component({ template: `` })
export class LacertaDynamicPageWithMetaComponent<T extends LacertaWagtailPage> extends LacertaDynamicTypeComponent<T> {
  currentDetailPage$: Observable<T> = this.lacertaCmsNgrxFacade.currentDetailPage$.pipe(
    take(1),
    map((page) => page as T)
  );

  constructor(protected lacertaCmsNgrxFacade: LacertaCmsNgrxFacade) {
    super();
  }
}
