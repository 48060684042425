import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'odd' })
export class OddPipe<T> implements PipeTransform {
  transform(value: T[]): T[] {
    if (!value) {
      return value;
    }
    return value.filter((_, idx) => idx % 2 === 1);
  }
}
