import { MetaDefinition } from '@angular/platform-browser';

export enum LacertaMetaField {
  title,
  description,
  appName,
  image,
  imageWidth,
  imageHeight,
  url,
  twitterCard,
}

export type LacertaMetaFields = { [key in LacertaMetaField]?: string };

export type LacertaMetaFieldDefinitions = { [key in LacertaMetaField]?: MetaDefinition };

const htmlMetaFields: LacertaMetaFieldDefinitions = {
  [LacertaMetaField.description]: { name: 'description' },
};

const facebookMetaFields: LacertaMetaFieldDefinitions = {
  [LacertaMetaField.description]: { property: 'og:description' },
  [LacertaMetaField.image]: { property: 'og:image' },
  [LacertaMetaField.imageWidth]: { property: 'og:image:width' },
  [LacertaMetaField.imageHeight]: { property: 'og:image:height' },
  [LacertaMetaField.url]: { property: 'og:url' },
  [LacertaMetaField.appName]: { property: 'og:site_name' },
  [LacertaMetaField.title]: { property: 'og:title' },
};

const twitterMetaFields: LacertaMetaFieldDefinitions = {
  [LacertaMetaField.twitterCard]: { name: 'twitter:card' },
  [LacertaMetaField.title]: { name: 'twitter:title' },
  [LacertaMetaField.description]: { name: 'twitter:description' },
  [LacertaMetaField.image]: { name: 'twitter:image:src' },
};

export const allMetaFieldDefinitions: LacertaMetaFieldDefinitions[] = [htmlMetaFields, facebookMetaFields, twitterMetaFields];
