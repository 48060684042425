import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as WagtailActions from './cms.actions';
import { LacertaWagtailPage } from '../page.model';

export const CMS_FEATURE_KEY = 'cms';

export interface State {
  error?: string | null;
  rootPage?: number;
  menuPageIds?: number[];
  summaryPages: EntityState<LacertaWagtailPage>;
  detailPages: EntityState<LacertaWagtailPage>;
  pagesCountByType: Record<string, number>;
  language?: string;
}

export interface CmsPartialState {
  readonly [CMS_FEATURE_KEY]: State;
}

export const pageAdapter: EntityAdapter<LacertaWagtailPage> = createEntityAdapter<LacertaWagtailPage>({ selectId: (model) => model.id });

export const initialState: State = {
  summaryPages: pageAdapter.getInitialState({}),
  detailPages: pageAdapter.getInitialState({}),
  pagesCountByType: {},
};

const cmsReducer = createReducer(
  initialState,
  on(WagtailActions.loadRootPage, (state): State => ({ ...state, error: null })),
  on(WagtailActions.loadRootPageSuccess, (state, { page }) => ({
    ...state,
    rootPage: page.id,
    summaryPages: pageAdapter.addOne(page, state.summaryPages),
  })),

  on(WagtailActions.loadPageById, (state): State => ({ ...state, error: null })),
  on(WagtailActions.loadPageByIdSuccess, (state, { page }) => ({ ...state, detailPages: pageAdapter.addOne(page, state.detailPages) })),

  on(WagtailActions.loadPageBySlug, (state): State => ({ ...state, error: null })),
  on(WagtailActions.loadPageBySlugSuccess, (state, { page }) => ({
    ...state,
    summaryPages: pageAdapter.addOne(page, state.summaryPages),
  })),

  on(WagtailActions.loadPagesByType, (state): State => ({ ...state, error: null })),
  on(WagtailActions.loadPagesByTypeSuccess, (state, { pageType, pages }) => ({
    ...state,
    detailPages: pageAdapter.addMany(pages.items, state.detailPages),
    pagesCountByType: {
      ...state.pagesCountByType,
      [pageType]: pages.meta.total_count,
    },
  })),

  on(WagtailActions.loadMenu, (state): State => ({ ...state, error: null })),
  on(WagtailActions.loadMenuSuccess, (state, { pages }) => ({
    ...state,
    summaryPages: pageAdapter.upsertMany(pages, state.summaryPages),
    menuPageIds: pages.map((page) => page.id),
  })),

  on(WagtailActions.changeLanguage, (state, { language }): State => ({ ...state, language, error: null })),

  on(WagtailActions.failure, (state, { error }): State => ({ ...state, ...(error ? [error] : []) }))
);

export function reducer(state: State | undefined, action: Action) {
  return cmsReducer(state, action);
}
