import { LacertaUiFocalPoint } from './image.model';

export const buildBackgroundPosition = (focalPoint?: LacertaUiFocalPoint) => {
  if (focalPoint) {
    const xCenter = (focalPoint.right + focalPoint.left) / 2;
    const yCenter = (focalPoint.bottom + focalPoint.top) / 2;

    const pctModifier = 100;
    const percentageX = (xCenter / focalPoint.width) * pctModifier;
    const percentageY = (yCenter / focalPoint.height) * pctModifier;

    const backgroundPosition = `${percentageX.toFixed(0)}% ${percentageY.toFixed(0)}%`;

    return backgroundPosition;
  } else {
    return 'center bottom';
  }
};
