import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as LacertaNgrxActions from './router.actions';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class LacertaNgrxRouterEffects {
  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LacertaNgrxActions.routerNavigate),
        tap((action) => this.router.navigateByUrl(action.url))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private router: Router) {}
}
