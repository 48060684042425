<div class="background"></div>
<div class="snake white-theme">
  <riff-snake [amount]="6"></riff-snake>

  <section class="container">
    <div class="logo nosnake">
      <img src="../../../assets/images/svg/Logo_wit_transparant.svg" alt="Riff" />
      <div class="tagline">we run on music</div>
    </div>

    <div class="address nosnake">
      Akkerstraat 76C<br />
      9000 Gent<br />
      <span class="VAT">BTW BE 0808 622 286</span>
    </div>

    <div class="text nosnake">
      Je kunt voor bijna alles terecht bij onze director of everything else:<br />
      <a href="mailto:femke@riff-agency.be">femke@riff-agency.be</a><br />
      <a href="tel:+32477933425">+32(0)477 93 34 25</a>
    </div>

    <div class="links nosnake">
      <a href="https://www.instagram.com/riffagency/" target="_blank"><fa-icon [icon]="faInstagram"></fa-icon></a>
      <a href="https://open.spotify.com/playlist/1Mgf5IFGqbCgR7iw1XZtPp?si=a9DLAz5IR4KyVToXMT9_HQ" target="_blank"
        ><fa-icon [icon]="faSpotify"></fa-icon
      ></a>
      <button class="play" (click)="showPopup = !showPopup">play SNAKE</button>
    </div>

    <div class="clear"></div>

    <div *ngIf="showPopup" class="popup">
      <div class="close" (click)="showPopup = false"><fa-icon [icon]="faTimes"></fa-icon></div>
      <h3>Oh no, you won't!</h3>
      <div class="jump-2">
        <p>Fun before pleasure.</p>
        <p>Eerst die <a href="mailto:femke@riff-agency.be">mail</a>, <a href="tel:+32477933425">telefoon</a>, afspraak en dan spelen.</p>
      </div>
      <button class="nevermind" (click)="play()">Nevermind. I'm a player.</button>
    </div>
  </section>
</div>

<footer>
  <a class="contact" routerLink="/home"><fa-icon [icon]="faTimes"></fa-icon></a>
</footer>
