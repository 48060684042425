import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

export const filterNullOrUndefined = <T>() => (source$: Observable<null | undefined | T>) =>
  source$.pipe(filter(inputIsNotNullOrUndefined));

function inputIsNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}

export const cast = <T>() => (source$: Observable<unknown | null | undefined>) => source$.pipe(map((value) => value as T));

const buildConsoleLogStyle = (backgroundColor: string, color: string, padding: string) =>
  `background: ${backgroundColor}; color: ${color}; padding: ${padding};`;
const debugStyles = {
  next: buildConsoleLogStyle('#009688', '#fff', '3px'),
  error: buildConsoleLogStyle('#E91E63', '#fff', '3px'),
  complete: buildConsoleLogStyle('#00BCD4', '#fff', '3px'),
} as const;
export const debug = <T>(tag: string = 'debug') =>
  tap<T>({
    next(value) {
      // eslint-disable-next-line no-console
      console.log(`%c[${tag}: Next]`, debugStyles.next, value);
    },
    error(error: unknown) {
      // eslint-disable-next-line no-console
      console.log(`%c[${tag}: Error]`, debugStyles.error, error);
    },
    complete() {
      // eslint-disable-next-line no-console
      console.log(`%c[${tag}]: Complete`, debugStyles.complete);
    },
  });
