import { TranslocoLoader } from '@ngneat/transloco';
import { Inject, Injectable } from '@angular/core';
import { LacertaTranslationConfig, TRANSLATION_CONFIG_TOKEN } from './translation.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LacertaTranslationLoader implements TranslocoLoader {
  constructor(private http: HttpClient, @Inject(TRANSLATION_CONFIG_TOKEN) public lacertaTranslationConfig?: LacertaTranslationConfig) {}

  getTranslation(langPath: string) {
    return this.lacertaTranslationConfig?.importTranslationFile
      ? this.lacertaTranslationConfig?.importTranslationFile(langPath)
      : this.http.get(`/assets/i18n/${langPath}.json`);
  }
}
