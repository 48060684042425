import { Directive, HostBinding, Input } from '@angular/core';
import { OnChange } from '@lacerta/util';
import { buildBackgroundPosition } from './image.util';
import type { LacertaUiImage } from './image.model';

@Directive({
  selector: '[lacertaBackgroundImage]',
})
export class LacertaBackgroundImageDirective {
  @OnChange('handleImageChange')
  @Input()
  lacertaBackgroundImage?: LacertaUiImage;

  @HostBinding('style.background-position') backgroundPosition?: string;
  @HostBinding('style.background-image') backgroundImage?: string;

  handleImageChange(image: LacertaUiImage): void {
    this.backgroundPosition = buildBackgroundPosition(image.focalPoint);
    if (image) {
      this.backgroundImage = `url(${image.src})`;
    }
  }
}
