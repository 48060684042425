import { Component } from '@angular/core';
import { LacertaDynamicTypeComponent } from './dynamic-type.component';

@Component({
  template: `
    <pre>Unmapped component of type << {{ type }} >></pre>
    <pre>{{ context | json }}</pre>
  `,
  styles: [
    `
      :host {
        display: block;
        padding-top: 150px;
      }
    `,
  ],
})
export class UnknownBlockComponent extends LacertaDynamicTypeComponent<unknown> {}
