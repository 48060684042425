import { AfterViewChecked, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
import { Events, SnakeService } from './snake.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PlayField } from './play-field.model';
import { Snake } from './snake';
import { Fruit } from './fruit.model';
import { CollisionError } from './collision-error.model';

const getDefaultFontSize = (parentElement: HTMLElement) => {
  const elementToModify = parentElement || document.body;
  const div = document.createElement('div');
  div.style.width = '10000rem';
  elementToModify.appendChild(div);
  const horizontalPixels = 10000;
  const pixels = div.offsetWidth / horizontalPixels;
  elementToModify.removeChild(div);
  return pixels;
};

const defaultAmount = 6;
const defaultTickRate = 0.1;
const aiTickRate = 0.05;
const timeout = 50;
const mSecInSec = 1000;

@UntilDestroy()
@Component({
  selector: 'riff-snake',
  templateUrl: './snake.component.html',
  styleUrls: ['./snake.component.scss'],
})
export class SnakeComponent implements OnInit, AfterViewChecked {
  constructor(private snakeService: SnakeService) {}
  @ViewChild('playground', { read: ElementRef, static: true }) playground: ElementRef;
  @ViewChild('canvas', { read: ElementRef, static: true }) canvas: ElementRef;
  @ViewChild('black_arc', { read: ElementRef, static: true }) blackArc: ElementRef;
  @ViewChild('red_arc', { read: ElementRef, static: true }) redArc: ElementRef;
  @ViewChild('white_arc', { read: ElementRef, static: true }) whiteArc: ElementRef;
  @ViewChild('red_arc_svg', { read: ElementRef, static: true }) redArcSvg: ElementRef;

  @Input() waitForIt = false;
  @Input() play = false;
  @Input() amount = defaultAmount;
  @Input() theme = 'white';
  @Input() color = '';
  @Input() auto = false;

  width = 0;
  height = 0;
  score = 0;
  gameover = false;

  private readonly playfield = new PlayField();
  private initialViewCheck = false;
  private tickRate = defaultTickRate;
  private snake: Snake;
  private ctx: CanvasRenderingContext2D;
  private interval$: Observable<number>;
  private intervalSubscription: Subscription;

  private readonly playTickRate = defaultTickRate;

  ngOnInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');

    this.snakeService.events$.pipe(untilDestroyed(this)).subscribe((event) => {
      setTimeout(() => {
        switch (event) {
          case Events.ai:
            this.auto = true;
            this.play = true;
            this.tickRate = aiTickRate;
            this.renderSnakeField();
            break;
          case Events.play:
            this.auto = false;
            this.play = true;
            this.tickRate = this.playTickRate;
            this.renderSnakeField();
            break;
          case Events.render:
            this.auto = false;
            this.play = false;
            this.renderSnakeField();
            break;
        }
      }, timeout);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.renderSnakeField();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowLeft':
        this.move('left');
        break;
      case 'ArrowUp':
        this.move('up');
        break;
      case 'ArrowRight':
        this.move('right');
        break;
      case 'ArrowDown':
        this.move('down');
        break;
    }
  }

  ngAfterViewChecked(): void {
    if (!this.initialViewCheck) {
      this.initialViewCheck = true;
      if (!this.waitForIt) {
        setTimeout(() => {
          this.renderSnakeField();
        }, timeout);
      }
    }
  }

  private renderSnakeField() {
    this.gameover = false;
    this.playfield.scale = getDefaultFontSize(this.playground.nativeElement) * 2;
    this.playfield.forbiddenZones = Array.from(document.getElementsByClassName('nosnake'));
    this.playfield.offsetX = this.playground.nativeElement.getBoundingClientRect().left;
    this.playfield.offsetY = this.playground.nativeElement.getBoundingClientRect().top;

    this.playfield.cols = Math.floor(this.playground.nativeElement.clientWidth / this.playfield.scale);
    this.playfield.rows = Math.floor(this.playground.nativeElement.clientHeight / this.playfield.scale);
    this.width = this.playground.nativeElement.clientWidth;
    this.height = this.playground.nativeElement.clientHeight;

    this.playfield.arc = this.blackArc.nativeElement;
    this.playfield.color = '#000';

    if (this.color) {
      this.playfield.color = '#FFF';
      this.playfield.arc = this.whiteArc.nativeElement;
      const img = new Image();
      const svg = this.redArcSvg.nativeElement.innerHTML.replace(/#f66a55/g, this.color);
      img.src = `data:image/svg+xml;charset=utf-8,${svg.replace('#', '%23')}`;
      this.playfield.arcAlt = img;
      this.playfield.altColor = this.color;
    } else {
      switch (this.theme) {
        case 'red':
          this.playfield.arcAlt = this.redArc.nativeElement;
          this.playfield.altColor = '#f66a55';
          break;
        case 'red-white':
          this.playfield.arc = this.whiteArc.nativeElement;
          this.playfield.color = '#FFF';
          this.playfield.arcAlt = this.redArc.nativeElement;
          this.playfield.altColor = '#f66a55';
          break;
        default:
          this.playfield.arcAlt = this.whiteArc.nativeElement;
          this.playfield.altColor = '#FFF';
      }
    }

    this.score = 0;

    this.snake = new Snake(this.playfield, Math.floor(this.playfield.cols / 2), this.playfield.rows);
    this.snake.addToTail();

    this.playfield.fruits = [];
    this.addFruit();

    if (this.play) {
      this.startGame();
    } else {
      setTimeout(() => {
        this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
        for (const fruit of this.playfield.fruits) {
          fruit.draw(this.ctx);
        }
      }, timeout);
    }
  }

  private addFruit() {
    const segmentColumns = Math.floor(this.amount / 2);
    const segmentRows = Math.floor(this.amount / 3);
    const segmentWidth = Math.floor(this.playfield.cols / segmentColumns) - 1;
    const segmentHeight = Math.floor(this.playfield.rows / segmentRows) - 1;

    const newFruits = [...new Array(this.amount).keys()].map(
      (amount) =>
        new Fruit(this.playfield, {
          xValue: segmentWidth * ((amount + 1) % segmentColumns),
          yValue: segmentHeight * ((amount + 1) % segmentRows),
          height: segmentHeight,
          width: segmentWidth,
        })
    );
    this.playfield.fruits.push(...newFruits);
    this.playfield.fruits.forEach((fruit) => fruit.generatePos(this.snake));
  }

  private startGame() {
    this.snakeService.started();
    this.interval$ = interval(this.tickRate * mSecInSec);
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
    this.intervalSubscription = this.interval$.pipe(untilDestroyed(this)).subscribe(() => {
      try {
        if (this.auto) {
          if (this.playfield.fruits.length === 0) {
            this.addFruit();
            this.snakeService.started();
          }
          this.autoMove();
        }

        this.update();
        this.draw();
      } catch (ex) {
        if (ex instanceof CollisionError) {
          if (this.auto) {
            this.renderSnakeField();
            return;
          }
          this.gameover = true;
          this.intervalSubscription.unsubscribe();
        }
      }
    });
  }

  private autoMove() {
    this.playfield.fruits.sort((fruit1, fruit2) => {
      const diffA = Math.abs(this.snake.xValue - fruit1.xValue) + Math.abs(this.snake.yValue - fruit1.yValue);
      const diffB = Math.abs(this.snake.xValue - fruit2.xValue) + Math.abs(this.snake.yValue - fruit2.yValue);
      return diffA - diffB;
    });

    if (!this.playfield.fruits[0].getRows().includes(this.snake.yValue)) {
      if (this.playfield.fruits[0].getRows()[0] < this.snake.yValue) {
        this.move('up');
      } else {
        this.move('down');
      }
    } else {
      if (this.playfield.fruits[0].getColumns()[0] < this.snake.xValue) {
        this.move('left');
      } else {
        this.move('right');
      }
    }
  }

  private update() {
    this.snake.update();

    for (const fruit of this.playfield.fruits) {
      const score = fruit.check(this.snake, !this.auto);
      if (score > 0) {
        this.snakeService.score();
        this.score += score;
      }
    }
  }

  private draw() {
    this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.snake.draw(this.ctx);
    for (const fruit of this.playfield.fruits) {
      fruit.draw(this.ctx);
    }
    this.ctx.fillStyle = '#ccc';
  }

  close() {
    this.gameover = false;
  }

  restart() {
    this.renderSnakeField();
  }

  nothing(event: Event) {
    event.stopPropagation();
  }

  move(direction: string) {
    if (this.play) {
      switch (direction) {
        case 'left':
          if (this.snake.xvel !== 1) {
            this.snake.changeDir(-1, 0);
          }
          break;
        case 'up':
          if (this.snake.yvel !== 1) {
            this.snake.changeDir(0, -1);
          }
          break;
        case 'right':
          if (this.snake.xvel !== -1) {
            this.snake.changeDir(1, 0);
          }
          break;
        case 'down':
          if (this.snake.yvel !== -1) {
            this.snake.changeDir(0, 1);
          }
          break;
      }
    }
  }
}
