import { ModuleWithProviders, NgModule } from '@angular/core';
import { defaultConfig, TRANSLOCO_CONFIG, TRANSLOCO_LOADER, TRANSLOCO_MISSING_HANDLER, TranslocoModule } from '@ngneat/transloco';
import { HttpClientModule } from '@angular/common/http';
import { LacertaTranslationLoader } from './translation-loader.service';
import { LacertaTranslationDirective } from './translation.directive';
import { LacertaTranslationPipe } from './translation.pipe';
import { LacertaTranslationMissingHandler } from './translation-missing-handler';
import { LacertaTranslationConfig, TRANSLATION_CONFIG_TOKEN } from './translation.model';

@NgModule({
  imports: [TranslocoModule, HttpClientModule],
  declarations: [LacertaTranslationDirective, LacertaTranslationPipe],
  exports: [LacertaTranslationDirective, LacertaTranslationPipe],
})
export class LacertaTranslationModule {
  static forRoot(config: LacertaTranslationConfig): ModuleWithProviders<LacertaTranslationModule> {
    return {
      ngModule: LacertaTranslationModule,
      providers: [
        {
          provide: TRANSLOCO_CONFIG,
          useValue: {
            ...defaultConfig,
            ...(config.defaultLang && { defaultLang: config.defaultLang, availableLangs: [config.defaultLang] }),

            prodMode: config.prodMode,
            reRenderOnLangChange: true,
          },
        },
        {
          provide: TRANSLOCO_LOADER,
          useClass: LacertaTranslationLoader,
        },
        {
          provide: TRANSLOCO_MISSING_HANDLER,
          useClass: LacertaTranslationMissingHandler,
        },
        { provide: TRANSLATION_CONFIG_TOKEN, useValue: config },
      ],
    };
  }
}
