/* eslint-disable @typescript-eslint/naming-convention */
import { WagtailImage, WagtailImageRenditionField, WagtailStreamField } from '@lacerta/wagtail';
import { Team } from '../riff-wagtail.model';
import { LacertaEmbedBlock, LacertaImageChooserBlock, LacertaWagtailPage } from '@lacerta/cms';

export interface Paragraph {
  title: string;
  text: string;
}

export interface RiffProjectIndexPage extends LacertaWagtailPage {
  intro: string;
}

export interface RiffProjectPage extends LacertaWagtailPage {
  background_color: string;
  banner: WagtailImage;
  banner_text_color: string;
  thumbnail: WagtailImageRenditionField;
  tag_line: string;
  we_did: string;
  year: string;
  client: string;
  ziet_er_link_uit: WagtailStreamField<string>[];
  text: WagtailStreamField<Paragraph>[];
  presentation_images: WagtailStreamField<LacertaImageChooserBlock | LacertaEmbedBlock>[];
  presentation_text: string;
  team: WagtailStreamField<Team>[];
}

export enum RiffProjectField {
  year = 'year',
  tag_line = 'tag_line',
  thumbnail = 'thumbnail',
}
