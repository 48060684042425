<ng-container *ngIf="currentDetailPage$ | async as fullPage">
  <div class="background" [ngStyle]="{ 'background-color': fullPage.background_color }"></div>

  <section
    class="banner"
    (swipeleft)="goToPrevious()"
    (swiperight)="goToNext()"
    [ngClass]="fullPage.banner_text_color"
    [ngStyle]="fullPage.banner ? { 'background-image': 'url(' + fullPage.banner.meta?.download_url + ')' } : null"
  >
    <riff-snake theme="red-white" [color]="fullPage.background_color" [amount]="10" [waitForIt]="true"></riff-snake>
    <div class="title">
      <div class="arrow" (click)="scroll(title)"></div>
      <h1 #title class="nosnake" [ngClass]="{ wide: fullPage.title.length > 20 }" [innerHtml]="fullPage.title"></h1>
    </div>
    <div class="previous nosnake" *ngIf="previous">
      <a [routerLink]="'../' + previous.meta.slug"
        >{{ previous.title }}
        <div class="arrow"></div
      ></a>
    </div>
    <div class="next nosnake" *ngIf="next">
      <a [routerLink]="'../' + next.meta.slug">
        {{ next.title }}
        <div class="arrow"></div
      ></a>
    </div>
  </section>
  <section class="detail">
    <div class="nosnake container">
      <div class="columns">
        <div class="column" *ngIf="fullPage.we_did">
          <h4>WE DID</h4>
          <div class="intro" [innerHtml]="fullPage.we_did"></div>
        </div>
        <div class="column year" *ngIf="fullPage.year">
          <h4>YEAR</h4>
          <div class="intro" [innerHtml]="fullPage.year"></div>
        </div>
        <div class="column" *ngIf="fullPage.client">
          <h4>CLIENT</h4>
          <div class="intro" [innerHtml]="fullPage.client"></div>
        </div>
        <div class="column" *ngIf="fullPage.ziet_er_link_uit && fullPage.ziet_er_link_uit.length > 0">
          <h4>ZIET ER LINK UIT</h4>
          <ul class="links">
            <li *ngFor="let link of fullPage.ziet_er_link_uit">
              <a *ngIf="link.value" [href]="link.value" target="_blank">{{ link.value }}</a>
              <span *ngIf="!link.value">{{ link.value }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="text container">
    <div class="paragraph" *ngFor="let paragraph of (currentDetailPage$ | async)?.text">
      <div class="columns">
        <div class="title">{{ paragraph.value.title }}</div>
        <div class="column"><div [innerHtml]="paragraph.value.text"></div></div>
      </div>
    </div>
  </section>
  <section class="presentation container">
    <div class="columns">
      <div class="text" [innerHtml]="fullPage.presentation_text"></div>
      <div class="gallery"><riff-project-gallery [images]="fullPage.presentation_images"></riff-project-gallery></div>
    </div>
  </section>
  <section class="team container">
    <table>
      <tr>
        <th></th>
        <th>CREDITS</th>
      </tr>
      <tr *ngFor="let team of fullPage.team">
        <td class="label">{{ team.value.name }}</td>
        <td>
          <span *ngFor="let member of team.value.members; let isLast = last"> {{ member.name }}{{ !isLast ? ',' : '' }} </span>
        </td>
      </tr>
    </table>
  </section>
</ng-container>

<footer><a class="contact nosnake" routerLink="/contact">contact</a></footer>
