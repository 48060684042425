import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RiffWagtailProjectTypePageComponent } from './project/project-type.component';
import { ProjectModule } from '../../features/project/project.module';
import { RiffWagtailProjectPageComponent } from './project/project.component';
import { ProjectService } from './project/project.service';
import { RiffWagtailHomeComponent } from './home/home.component';
import { HomeModule } from '../../features/home/home.module';
import { CountdownModule } from '../../features/countdown/countdown.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SnakeModule } from '../../features/snake/snake.module';
import { WijZijnModule } from '../../features/wijzijn/wij-zijn.module';
import { ContactModule } from '../../features/contact/contact.module';
import { RiffWagtailContactPageComponent } from './contact/contact.component';
import { RiffWagtailWijZijnPageComponent } from './wij-zijn/wij-zijn.component';

const dynamicComponents = [
  RiffWagtailHomeComponent,
  RiffWagtailProjectTypePageComponent,
  RiffWagtailProjectPageComponent,
  RiffWagtailContactPageComponent,
  RiffWagtailWijZijnPageComponent,
];
const featureModules = [ProjectModule, HomeModule, CountdownModule, SnakeModule, WijZijnModule, ContactModule];

@NgModule({
  imports: [FontAwesomeModule, CommonModule, featureModules],
  declarations: [dynamicComponents],
  providers: [ProjectService],
})
export class RiffWagtailModule {}
