import { Injectable, Type } from '@angular/core';
import { LacertaDynamicTypeComponent } from './dynamic-type.component';
import { UnknownBlockComponent } from './unknown-block.component';
import { LacertaCmsConfig, LacertaComponentDefinition } from '../cms.config';

@Injectable({ providedIn: 'root' })
export class LacertaDynamicComponentService {
  private componentMapping: Map<string, LacertaComponentDefinition>;

  constructor(lacertaCmsConfig: LacertaCmsConfig) {
    this.componentMapping = new Map(lacertaCmsConfig.wagtailComponentTypeMapping);
  }

  mapTypeToComponent(type: string): Promise<Type<LacertaDynamicTypeComponent<unknown>>> {
    const componentDefinition: LacertaComponentDefinition | undefined = this.componentMapping.get(type);
    if (componentDefinition === undefined || componentDefinition.name?.length) {
      return new Promise<Type<LacertaDynamicTypeComponent<unknown>>>((resolve) => {
        resolve((componentDefinition as Type<LacertaDynamicTypeComponent<unknown>>) || UnknownBlockComponent);
      });
    } else {
      return (componentDefinition as () => Promise<Type<LacertaDynamicTypeComponent<unknown>>>)();
    }
  }

  hasMappingForType = (type: string) => this.componentMapping.has(type);
}
