import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { LinkToButtonPipe } from './link-to-button.pipe';
import { RouteTransformerDirective } from './route-transformer.directive';
import { ExternalLinkBlankPipe } from './external-link-blank.pipe';
import { BackendLinkPipe } from './backend-link.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { WagtailImageRenditionPipe } from './image-rendition.pipe';
import { AsRenderedUiImagePipe, AsRenderedUiImagesPipe } from './as-ui-image.pipe';
import { StreamFieldsToValuesPipe } from './streamfields-to-values.pipe';
import { AnchorLinkPipe } from './anchor-link.pipe';
import { VideoAutoresizeDirective } from './video-autoresize.directive';

const declarations = [
  AnchorLinkPipe,
  AsRenderedUiImagePipe,
  AsRenderedUiImagesPipe,
  BackendLinkPipe,
  ExternalLinkBlankPipe,
  VideoAutoresizeDirective,
  LinkToButtonPipe,
  RouteTransformerDirective,
  SafeUrlPipe,
  SanitizeHtmlPipe,
  StreamFieldsToValuesPipe,
  WagtailImageRenditionPipe,
];

@NgModule({
  imports: [CommonModule],
  declarations,
  exports: declarations,
})
export class LacertaCmsTransformersModule {}
