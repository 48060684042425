import { Component, Input, OnInit } from '@angular/core';
import { RiffProjectPage } from '../../../core/riff-wagtail/project/project.model';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ProjectService } from '../../../core/riff-wagtail/project/project.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SnakeService } from '../../snake/snake.service';
import { Router } from '@angular/router';
import { LacertaCmsNgrxFacade } from '@lacerta/cms';

@UntilDestroy()
@Component({
  selector: 'riff-project-detail-page',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss'],
})
export class ProjectDetailComponent implements OnInit {
  @Input() project?: RiffProjectPage;

  currentDetailPage$: Observable<RiffProjectPage> = this.ngrxFacade.currentDetailPage$.pipe(map((page) => page as RiffProjectPage));
  previous: RiffProjectPage;
  next: RiffProjectPage;

  constructor(
    private ngrxFacade: LacertaCmsNgrxFacade,
    private projectService: ProjectService,
    private snakeService: SnakeService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentDetailPage$
      .pipe(
        untilDestroyed(this),
        filter((fullPage) => !!fullPage.meta.parent?.id),
        map((fullPage) => ({ pageId: fullPage.id, parentId: fullPage.meta.parent?.id as number })),
        switchMap(({ pageId, parentId }) => this.projectService.getByParentId(parentId).pipe(map((projects) => ({ projects, pageId })))),
        map(({ projects, pageId }) => ({ projects, indexCurrentProject: projects.findIndex((project) => project.id === pageId) })),
        tap(
          ({ projects, indexCurrentProject }) =>
            (this.previous = projects[indexCurrentProject === 0 ? projects.length - 1 : indexCurrentProject - 1])
        ),
        tap(
          ({ projects, indexCurrentProject }) =>
            (this.next = projects[indexCurrentProject === projects.length - 1 ? 0 : indexCurrentProject + 1])
        ),
        tap(() => this.snakeService.render())
      )
      .subscribe();
  }

  scroll(target: HTMLHeadingElement) {
    target.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  goToNext() {
    this.router.navigate(['../', this.next.meta.slug]);
  }

  goToPrevious() {
    this.router.navigate(['../', this.previous.meta.slug]);
  }
}
