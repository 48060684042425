import { Component, Inject, InjectionToken, Input, Optional } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LacertaFormControlType, LacertaFormField, LacertaFormSubmitStatus } from './form.model';

export interface LacertaFormMessages {
  errors: {
    [key: string]: string;
  };
  submitStatus?: {
    submittedInvalid?: string;
    submittedValid?: string;
    submitSuccess?: string;
    submitError?: string;
  };
}

export const FORM_ERROR_MESSAGES = new InjectionToken<LacertaFormMessages>('form.error.messages');

@Component({
  selector: 'lacerta-form-control',
  template: '',
})
export class LacertaFormControlComponent {
  @Input() field?: LacertaFormField<unknown>;
  @Input() form?: FormGroup;
  @Input() submitStatus?: LacertaFormSubmitStatus;
  @Input() formMessages?: LacertaFormMessages;

  public lacertaFormControlType = LacertaFormControlType;
  public lacertaFormSubmitStatus = LacertaFormSubmitStatus;

  constructor(@Optional() @Inject(FORM_ERROR_MESSAGES) public injectedFormMessages: LacertaFormMessages) {}

  get errorMessage() {
    if (this.isInvalid) {
      const firstErrorKey = this.errors && Object.keys(this.errors)[0];
      const validationMessage = firstErrorKey && this.formMessagesLookup?.errors[firstErrorKey];
      return validationMessage ? validationMessage : `Validation (${firstErrorKey}) failed for this field`;
    } else {
      return undefined;
    }
  }

  get formMessagesLookup() {
    return this.injectedFormMessages ?? this.formMessages;
  }

  get isInvalid() {
    const field = this.formControl;
    return field?.invalid && (field.touched || this.submitStatus !== LacertaFormSubmitStatus.NOT_SUBMITTED);
  }

  get errors() {
    return this.formControl?.errors;
  }

  get formControl() {
    return this.field?.key ? this.form?.controls[this.field.key] : undefined;
  }

  get isDisabled() {
    return [LacertaFormSubmitStatus.SUBMITTED_VALID, LacertaFormSubmitStatus.SUBMIT_SUCCESS].some((status) => status === this.submitStatus);
  }

  get isLoading() {
    return this.submitStatus === LacertaFormSubmitStatus.SUBMITTED_VALID;
  }
}
