import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RiffHomeComponent } from './home.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { LacertaBulmaModule } from '@lacerta/bulma';
import { InlineSVGModule } from 'ng-inline-svg';
import { SnakeModule } from '../snake/snake.module';

@NgModule({
  imports: [SharedModule, ReactiveFormsModule, NgxCaptchaModule, LacertaBulmaModule, InlineSVGModule.forRoot(), SnakeModule],
  declarations: [RiffHomeComponent],
  exports: [RiffHomeComponent],
})
export class HomeModule {}
