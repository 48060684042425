import { Pipe, PipeTransform, Type } from '@angular/core';
import { LacertaDynamicComponentService } from './dynamic-component.service';
import { LacertaDynamicTypeComponent } from './dynamic-type.component';

@Pipe({
  name: 'mapToComponent',
})
export class MapToComponentPipe implements PipeTransform {
  constructor(private lacertaDynamicComponentService: LacertaDynamicComponentService) {}

  transform(type: string): Promise<Type<LacertaDynamicTypeComponent<unknown>>> {
    return this.lacertaDynamicComponentService.mapTypeToComponent(type);
  }
}
