import { InjectionToken } from '@angular/core';
import { Translation } from '@ngneat/transloco';
import { Observable } from 'rxjs';

export const TRANSLATION_CONFIG_TOKEN = new InjectionToken<LacertaTranslationConfig>('lacertaTranslationConfig');

export interface LacertaTranslationConfig {
  prodMode: boolean;
  defaultLang?: string;
  importTranslationFile?: (lang: string) => Observable<Translation>;
}
