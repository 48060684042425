import { Component, Input } from '@angular/core';
import { WagtailImageRenditionField, WagtailMenuPage } from '@lacerta/wagtail';
import { SnakeService } from '../../features/snake/snake.service';
import { faInstagram, faSpotify } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'riff-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  faInstagram = faInstagram;
  faSpotify = faSpotify;

  @Input() logo: WagtailImageRenditionField;
  @Input() items: WagtailMenuPage[] | null;
  @Input() parentPath = '';

  menuActive = false;

  constructor(private snakeService: SnakeService) {}

  public routerLink(bulmaNavbarItem: WagtailMenuPage) {
    return `${this.parentPath}/${bulmaNavbarItem.page.meta.slug}`;
  }

  play() {
    this.menuActive = false;
    this.snakeService.play();
  }
}
