import { Component, Input } from '@angular/core';

export interface LacertaFullWidthImage {
  url: string;
  height: number;
  width: number;
}

const ratioToPct = 100;

@Component({
  selector: 'lacerta-full-width-image',
  template: `
    <div
      [style.background-image]="imageUrl"
      [style.padding-top]="paddingTop"
      [style.height]="limitHeight"
      [style.background-size]="backgroundSize || limitHeight ? 'cover' : 'contain'"
      [class]="'background-image' + (extraStylename ? ' ' + extraStylename : '')"
    >
      <div class="front">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      .background-image {
        position: relative;
        -webkit-background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 0;
      }

      .front {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    `,
  ],
})
export class FullWidthImageComponent {
  @Input() public image?: LacertaFullWidthImage;
  @Input() public extraStylename?: string;
  @Input() backgroundSize?: string;
  @Input() limitHeight?: string;

  get imageUrl(): string {
    return this.image?.url ? `url(${this.image.url})` : '';
  }

  get paddingTop() {
    return this.limitHeight ? undefined : `${(ratioToPct * this.imageHeight) / this.imageWidth}%`;
  }

  get imageHeight() {
    return this.image?.height ?? 0;
  }

  get imageWidth() {
    return this.image?.width ?? 0;
  }
}
