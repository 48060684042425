import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LacertaFormControlComponent } from './form-control.component';
import { LacertaFormComponent } from './form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicModule } from 'ng-dynamic-component';
import { LacertaFormTargetPipe } from './form-target.pipe';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, DynamicModule],
  declarations: [LacertaFormControlComponent, LacertaFormComponent, LacertaFormTargetPipe],
  exports: [LacertaFormComponent, ReactiveFormsModule, LacertaFormTargetPipe],
})
export class LacertaFormModule {}
