import { ModuleWithProviders, NgModule, Optional, Provider, SkipSelf } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { LacertaNgrxRouterEffects } from './router.effects';
import { LacertaNgrxConfig } from './ngrx.config';
import { preventReimport } from '@lacerta/util';

@NgModule({
  imports: [
    StoreModule.forRoot({ router: routerReducer }, { runtimeChecks: { strictStateImmutability: false } }),
    EffectsModule.forRoot([LacertaNgrxRouterEffects]),
    StoreRouterConnectingModule.forRoot({ routerState: 0 }),
  ],
})
export class LacertaNgrxModule {
  constructor(@Optional() @SkipSelf() parentModule: LacertaNgrxModule) {
    preventReimport(parentModule, 'LacertaNgrxModule');
  }

  static forRoot(config: LacertaNgrxConfig): ModuleWithProviders<LacertaNgrxModule> {
    return {
      ngModule: LacertaNgrxModule,
      providers: [...(!config.production ? [StoreDevtoolsModule.instrument({}).providers] : [])] as Provider[],
    };
  }
}
