import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './contact.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SnakeModule } from '../snake/snake.module';

@NgModule({
  declarations: [ContactComponent],
  imports: [CommonModule, FontAwesomeModule, SnakeModule],
  exports: [ContactComponent],
})
export class ContactModule {}
