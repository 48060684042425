import { PlayField } from './play-field.model';
import { CollisionError } from './collision-error.model';

export class Snake {
  tail: number[][] = [];
  length = 0;
  lockedInput = false;

  xValue = 0;
  yValue = 0;
  playfield;

  xvel = 0;
  yvel = -1;

  constructor(playfield: PlayField, xValue: number, yValue: number) {
    this.xValue = xValue || 0;
    this.yValue = yValue || 0;
    this.playfield = playfield;
  }

  changeDir(xvel: number, yvel: number) {
    if (!this.lockedInput) {
      this.xvel = xvel;
      this.yvel = yvel;
    }
    this.lockedInput = true;
  }

  addToTail() {
    this.length++;
    if (this.tail.length === 0) {
      this.tail.push([this.xValue, this.yValue]);
    }
    this.tail.push([this.tail[this.tail.length - 1][0], this.tail[this.tail.length - 1][1]]);
  }

  update() {
    this.xValue = this.xValue + this.xvel;
    this.yValue = this.yValue + this.yvel;
    if (this.xValue < 0) {
      // Check for LEFT edge collision
      this.xValue = this.playfield.cols - 1;
    }
    if (this.yValue < 0) {
      // Check for TOP edge collision
      this.yValue = this.playfield.rows - 1;
    }
    if (this.xValue > this.playfield.cols - 1 && this.xvel === 1) {
      // Check for right edge
      this.xValue = 0;
    }
    if (this.yValue > this.playfield.rows - 1 && this.yvel === 1) {
      // Check for bottom edge
      this.yValue = 0;
    }

    this.tail = this.tail.slice(0, length - 1);
    this.tail.unshift([this.xValue, this.yValue]);

    for (let index = 1; index < this.length - 1; index++) {
      if (this.xValue === this.tail[index][0] && this.yValue === this.tail[index][1]) {
        throw new CollisionError();
      }
    }
  }

  draw(ctx: CanvasRenderingContext2D) {
    this.lockedInput = false;
    ctx.fillStyle = this.playfield.color;
    ctx.fillRect(this.xValue * this.playfield.scale, this.yValue * this.playfield.scale, this.playfield.scale, this.playfield.scale);
    if (this.length > 0) {
      for (let index = 1; index < this.length; index++) {
        ctx.fillRect(
          this.tail[index][0] * this.playfield.scale,
          this.tail[index][1] * this.playfield.scale,
          this.playfield.scale,
          this.playfield.scale
        );
      }
    }
  }
}
