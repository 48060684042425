import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { LacertaBulmaModule } from '@lacerta/bulma';
import { InlineSVGModule } from 'ng-inline-svg';
import { SnakeComponent } from './snake.component';
import { SnakeService } from './snake.service';

@NgModule({
  imports: [SharedModule, ReactiveFormsModule, NgxCaptchaModule, LacertaBulmaModule, InlineSVGModule.forRoot()],
  providers: [SnakeService],
  declarations: [SnakeComponent],
  exports: [SnakeComponent],
})
export class SnakeModule {}
