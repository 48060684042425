import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { WijZijnModel } from './wij-zijn.model';
import { map } from 'rxjs/operators';
import { LacertaCmsNgrxFacade, LacertaDynamicWagtailPage } from '@lacerta/cms';
import { faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'riff-wij-zijn',
  templateUrl: './wij-zijn.component.html',
  styleUrls: ['./wij-zijn.component.scss'],
})
export class WijZijnComponent {
  faInstagram = faInstagram;
  faLinkedinIn = faLinkedinIn;

  @Input() page?: LacertaDynamicWagtailPage;
  fullPage$: Observable<WijZijnModel> = this.lacertaCmsNgrxFacade.currentDetailPage$.pipe(map((page) => page as WijZijnModel));

  constructor(private lacertaCmsNgrxFacade: LacertaCmsNgrxFacade) {}

  scroll(target: HTMLElement) {
    target.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
