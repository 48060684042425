export enum LacertaFormControlType {
  email,
  textbox,
  textarea,
  captcha,
  submit,
  checkbox,
  checkboxes,
  number,
}

export interface LacertaFormField<T> {
  value?: T;
  key?: string;
  label?: string;
  required?: boolean;
  order?: number;
  controlType: LacertaFormControlType;
  style?: string;
  placeholder?: string;
}

/* eslint-disable @typescript-eslint/naming-convention */
export enum LacertaFormSubmitStatus {
  NOT_SUBMITTED,
  SUBMITTED_INVALID,
  SUBMITTED_VALID,
  SUBMIT_SUCCESS,
  SUBMIT_ERROR,
}
/* eslint-enable @typescript-eslint/naming-convention */
