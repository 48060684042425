import { AfterViewInit, Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { RiffWagtailPageType } from '../../core/riff-wagtail/page/page.model';
import { Events, SnakeService } from '../snake/snake.service';
import { RiffProjectField, RiffProjectPage } from '../../core/riff-wagtail/project/project.model';
import { WagtailPagesApiService } from '@lacerta/wagtail';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LacertaWagtailPage } from '@lacerta/cms';

@UntilDestroy()
@Component({
  selector: 'riff-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class RiffHomeComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() page?: LacertaWagtailPage;

  projects: RiffProjectPage[] = [];
  projectIndex = -1;
  project: RiffProjectPage;
  playing = false;
  baseUrl: string;
  url: string;

  constructor(private renderer: Renderer2, private snakeService: SnakeService, private pagesService: WagtailPagesApiService) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'home');
    this.getProjects();
    this.playing = false;
    this.url = window.location.href;
    const nrPathParamsToTake = 3;
    this.baseUrl = window.location.href.split('/').slice(0, nrPathParamsToTake).join('/');

    this.snakeService.events$.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event === Events.started) {
        if (this.projects.length > 0) {
          this.projectIndex = this.projectIndex + 1 === this.projects.length ? 0 : this.projectIndex + 1;
          this.project = this.projects[this.projectIndex];
        }
      } else if (event === Events.play) {
        this.playing = true;
      }
    });
  }

  getProjects(): void {
    this.pagesService
      .get<RiffProjectPage>({
        type: RiffWagtailPageType.projectDetail,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        show_on_home: true,
        fields: [RiffProjectField.thumbnail, RiffProjectField.tag_line],
      })
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.projects = response.items;
        if (this.projectIndex < 0 && this.projects.length > 0) {
          this.projectIndex = 0;
        }
        this.project = this.projects[this.projectIndex];
      });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'home');
  }

  ngAfterViewInit(): void {
    this.snakeService.play(true);
  }
}
