import { Routes } from '@angular/router';
import { LacertaRoutePaths } from '@lacerta/util';
import { LacertaDynamicRoutePageComponent } from '../dynamic/dynamic-route-page.component';

export function buildRoutes(customRoutes: Routes): Routes {
  return [
    {
      path: '',
      redirectTo: LacertaRoutePaths.home,
      pathMatch: 'full',
    },
    {
      path: LacertaRoutePaths.pageNotFound,
      loadChildren: () => import('./page-not-found/page-not-found.module').then((value) => value.LacertaPageNotFoundModule),
    },
    ...(customRoutes ? customRoutes : []),
    {
      path: '**',
      component: LacertaDynamicRoutePageComponent,
    },
  ];
}
