import { Fruit } from './fruit.model';

export class PlayField {
  rows: number;
  cols: number;
  scale: number;
  fruits: Fruit[] = [];
  forbiddenZones: Element[];
  offsetX: number;
  offsetY: number;
  arc: CanvasImageSource;
  arcAlt: CanvasImageSource;
  color: string;
  altColor: string;
}
