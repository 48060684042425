import { Pipe, PipeTransform } from '@angular/core';
import { LacertaImageChooserBlock, LacertaImageChooserBlockImage } from '../block.model';
import { mapImageChooserBlockToRenditionImage } from './image.util';

@Pipe({
  name: 'wagtailImageRendition',
})
export class WagtailImageRenditionPipe implements PipeTransform {
  transform(imageBlock: LacertaImageChooserBlock, rendition: string): LacertaImageChooserBlockImage {
    return mapImageChooserBlockToRenditionImage(imageBlock, rendition);
  }
}
