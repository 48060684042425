import { Component } from '@angular/core';
import { LacertaCmsNgrxFacade } from '@lacerta/cms';

@Component({
  selector: 'riff-root',
  templateUrl: './app.component.html',
})
export class RiffAppComponent {
  menu$ = this.lacertaCmsNgrxFacade.menu$;

  constructor(private lacertaCmsNgrxFacade: LacertaCmsNgrxFacade) {}
}
