import { UrlSegment } from '@angular/router';

export const currentSlugByUrlSegment = (url: UrlSegment[]) => pathWithoutParams(url[url.length - 1].path);
export const currentSlugByUrl = (url: string) => {
  const slugAndParams = /\/([^/]+)\/?$/.exec(url);
  if (slugAndParams?.length) {
    return pathWithoutParams(slugAndParams[1]);
  }
  return '';
};
const pathWithoutParams = (path: string) => unescape(path).split(/[?#]/)[0];
export const pathWithoutFragment = (path: string) => unescape(path).split('#')[0];
