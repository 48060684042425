import { ModuleWithProviders, NgModule, Optional, Provider, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LacertaWagtailModule } from '@lacerta/wagtail';
import { LacertaCmsBlocksModule } from './blocks';
import { LacertaCmsTransformersModule } from './content-transformation';
import { preventReimport } from '@lacerta/util';
import { LacertaCmsConfig } from './cms.config';
import { LacertaCmsNgrxModule } from './+state/cms-ngrx.module';
import { LacertaDynamicModule } from './dynamic';
import { LacertaRouterModule } from './router/router.module';

@NgModule({
  imports: [
    CommonModule,
    LacertaCmsNgrxModule,
    LacertaCmsBlocksModule,
    LacertaCmsTransformersModule,
    LacertaDynamicModule,
    LacertaWagtailModule,
  ],
  exports: [LacertaCmsBlocksModule, LacertaCmsTransformersModule],
})
export class LacertaCmsModule {
  constructor(@Optional() @SkipSelf() parentModule: LacertaCmsModule) {
    preventReimport(parentModule, 'LacertaCmsModule');
  }

  static forRoot(config: LacertaCmsConfig): ModuleWithProviders<LacertaCmsModule> {
    return {
      ngModule: LacertaCmsModule,
      providers: [
        {
          provide: LacertaCmsConfig,
          useValue: config,
        },
        LacertaRouterModule.forRoot(config.customRoutes || []).providers as Provider[],
        LacertaWagtailModule.forRoot({ formsBaseUrl: '/api/forms/', pagesBaseUrl: '/api/v2/pages/' }).providers as Provider[],
      ],
    };
  }
}
